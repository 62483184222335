import { supabase } from './database'
import { captureException } from '@sentry/vue'

export const replaceSpacesWithUnderscores = (string) => {
	if (typeof string !== 'string') {
		throw new Error('Input must be a string')
	}
	return string.replace(/ /g, '_')
}

export const cleanUpFileName = (string) => {
	const pattern = /^[\w\/!.*'() &@$=;:+,?_-]+$/
	const timestamp = new Date().toISOString()
	let sanitizedFilename = Array.from(string)
		.filter((char) => pattern.test(char))
		.join('')
	const lastDotIndex = sanitizedFilename.lastIndexOf('.')

	if (lastDotIndex !== -1) {
		sanitizedFilename = `${sanitizedFilename.slice(
			0,
			lastDotIndex
		)}-${timestamp}${sanitizedFilename.slice(lastDotIndex)}`
	} else {
		sanitizedFilename += `-${timestamp}`
	}

	return sanitizedFilename
}

export const uploadPublicFileAndGetItsLink = async (bucketName, filePath, file) => {
	const { data, error } = await supabase.storage
		.from(bucketName)
		.upload(filePath, file, { upsert: true })
	if (error) {
		if (error.error !== 'Duplicate') {
			console.error(error)
			captureException(error)
			return
		}
		const { data: publicUrlData, error: publicErrorData } = supabase.storage
			.from(bucketName)
			.getPublicUrl(filePath)
		if (publicErrorData) {
			console.error(publicErrorData)
			captureException(publicErrorData)
		} else {
			return publicUrlData.publicUrl
		}
	} else {
		const { data: publicUrlData, error: publicErrorData } = supabase.storage
			.from(bucketName)
			.getPublicUrl(data.path)
		if (publicErrorData) {
			console.error(publicErrorData)
			captureException(publicErrorData)
		} else {
			return publicUrlData.publicUrl
		}
	}
}

export const capitalizeFirstLetter = (string) => {
	return string.charAt(0).toUpperCase() + string.slice(1)
}

export const removeMarkupTagsFromString = (string) => {
	const tagPattern = /<[^>]*>/g
	const strippedString = string.replace(tagPattern, '')
	return strippedString
}

export const decodeToken = (token) => {
	const base64Url = token.split('.')[1]
	const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/')
	const jsonPayload = decodeURIComponent(
		atob(base64)
			.split('')
			.map((c) => '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2))
			.join('')
	)

	const parsedToken = JSON.parse(jsonPayload)
	return parsedToken
}
