import { captureException } from '@sentry/vue'

class Node {
	constructor(data) {
		this.data = data
		this.children = []
	}
}

export default class Tree {
	constructor(rootData) {
		this.root = new Node(rootData)
		this.idSet = new Set()
		this.parentToOrphanListMap = new Map()
	}

	add(data, parent_deck_id) {
		const node = new Node(data)

		if (parent_deck_id) {
			// find parent
			const parent = this.findBFS(parent_deck_id)
			if (parent) {
				//add to parents child list
				parent.children.push(node)
			} else {
				//if there is no parent found
				if (this.parentToOrphanListMap.has(parent_deck_id)) {
					const array = this.parentToOrphanListMap.get(parent_deck_id)
					//add to orphan list
					if (!array.includes(data)) {
						array.push(data)
					}
				} else {
					//if there are orphans for current node, create new entry
					this.parentToOrphanListMap.set(parent_deck_id, [data])
				}
			}
		} else {
			//if decks has no parent push to root
			this.root.children.push(node)
		}

		//after adding node check if it has orphans
		if (this.parentToOrphanListMap.has(node.data.id)) {
			//add all orphans to tree
			const childList = this.parentToOrphanListMap.get(node.data.id)
			childList.forEach((element) => {
				this.add(element, element.parent_deck_id)
			})
			//remove entry to not double add orphans
			// this.parentToOrphanListMap.delete(node.data.id)
		}
	}

	findBFS(elementId) {
		const queue = [this.root]
		let _node = null
		while (queue.length) {
			const node = queue.shift()
			if (node.data.id === elementId) {
				_node = node
				break
			}
			for (const child of node.children) {
				queue.push(child)
			}
		}
		return _node
	}
	insertArray = (arr) => {
		const arrIds = new Set(arr.map((item) => item.id))
		while (arr.length !== 0) {
			let element = arr.shift()
			if (element.parent_deck_id && !this.idSet.has(element.parent_deck_id)) {
				if (arrIds.has(element.parent_deck_id)) {
					arr.push(element)
				} else {
					console.error(`Element with id ${element.id} has an invalid parent id!`)
					captureException(`Element with id ${element.id} has an invalid parent id!`)
				}
			} else {
				this.add(element, element.parent_deck_id)
				this.idSet.add(element.id)
			}
		}
	}

	updateNode(nodeData) {
		const nodeToUpdate = this.findBFS(nodeData.id)
		const parentNode = nodeToUpdate.data.parent_deck_id
			? this.findBFS(nodeToUpdate.data.parent_deck_id)
			: this.root
		if (nodeData.parent_deck_id == nodeToUpdate.data.parent_deck_id) {
			nodeToUpdate.data = nodeData
		} else {
			let newParent = this.findBFS(nodeData.parent_deck_id)
			if (!newParent) newParent = this.root
			parentNode.children = parentNode.children.filter((child) => child.data.id !== nodeData.id)
			nodeToUpdate.data = nodeData
			newParent.children.push(nodeToUpdate)
		}
	}

	removeNode(nodeId) {
		const _node = this.findBFS(nodeId)
		if (!_node) return
		if (_node.children.length !== 0) {
			_node.children.forEach((element) => {
				this.removeNode(element.data.id)
			})
		}
		if (_node.data.parent_deck_id) {
			const parent = this.findBFS(_node.data.parent_deck_id)
			parent.children = parent.children.filter((child) => child.data.id !== _node.data.id)
			this.idSet.delete(nodeId)
		} else {
			this.root.children = this.root.children.filter((child) => child.data.id !== _node.data.id)
			this.idSet.delete(nodeId)
		}
	}

	isDescendant(treeNode, targetId) {
		if (treeNode.data.id === targetId) {
			return true
		}

		for (const child of treeNode.children) {
			if (this.isDescendant(child, targetId)) {
				return true
			}
		}

		return false
	}

	getAllChildrenIds(nodeId) {
		if (!nodeId) return []
		const parentNode = this.findBFS(nodeId)
		if (!parentNode) {
			console.error(`Node with id ${nodeId} not found`)
			captureException(`Node with id ${nodeId} not found`)
			return []
		}

		const childrenIds = []
		this.traverseChildren(parentNode, (child) => {
			childrenIds.push(child.data.id)
		})

		return childrenIds
	}

	traverseChildren(node, callback) {
		callback(node)
		for (const child of node.children) {
			this.traverseChildren(child, callback)
		}
	}

	printTree() {
		console.dir(this.root, { depth: null })
	}
}
