<script>
import { useWindowSizeStore } from '../../store/store';

export default {
    name: 'StudyTime',
    setup() {
        const windowSizeStore = useWindowSizeStore()
        return {
            windowSizeStore
        }
    },
    props: {
        time: {
            type: Object,
            required: true
        },
    },
    data() {
        return {
            msg: 'message',
            today: 1200000,
            yesterday: 120000
        }
    },
    methods: {
        convertMillisecondsToHoursAndMinutes(milliseconds) {
            const totalMinutes = Math.floor(milliseconds / (1000 * 60));
            const hours = Math.floor(totalMinutes / 60);
            const minutes = totalMinutes % 60;

            return `${hours}h ${minutes}m`
        },
        getPercentageFromNumericValues(today, yesterday) {
            if (today < yesterday) {
                let x = 100 - ((today / yesterday) * 100)
                return `-${Math.round(x)}%`
            } else if (today === yesterday) {
                return '0%'
            } else {
                let y = 100 - ((yesterday / today) * 100)
                return `+${Math.round(y)}%`
            }
        },
    }
}

</script>

<template>
    <div :class="windowSizeStore.isDesktop ? 'component-card border' : 'component-card-mobile border'" id="studyTime">
        <p class="time-title">{{ $t('message.dashboard.studyTimeToday') }}</p>
        <p class="stats-highlight">{{ convertMillisecondsToHoursAndMinutes(time.today) }}</p>
        <p class="small-stats">
            <span :class="time.today > time.yesterday ? 'percentage positive' : 'percentage negative'">
                {{ getPercentageFromNumericValues(time.today, time.yesterday) }}
            </span>
            <span class="yesterday">&nbsp;{{ $t('message.dashboard.yesterday') }}</span>
            <span class="yesterday-number">{{
                convertMillisecondsToHoursAndMinutes(time.yesterday) }}
            </span>
            <img v-if="time.today > time.yesterday && !windowSizeStore.isDesktop" src="../../assets/graph/graph_up.svg"
                alt="" height="30" class="mobile-graph">
            <img v-else-if="!windowSizeStore.isDesktop" src="../../assets/graph/graph_down.svg" alt="" height="30"
                class="mobile-graph">
        </p>
        <div class="im" v-if="time.today > time.yesterday && windowSizeStore.isDesktop">
            <img src="../../assets/graph/graph_up.svg" alt="" height="50">
        </div>
        <div class="im" v-else-if="windowSizeStore.isDesktop">
            <img src="../../assets/graph/graph_down.svg" alt="" height="50">
        </div>
    </div>
</template>

<style scoped>
.mobile-graph {
    margin: auto 0 auto auto;
}

.yesterday {
    color: #9a9ea6;
    white-space: nowrap;
}

.small-stats {
    font-size: 14px;
    line-height: 20px;
    display: flex;
    align-items: center;
    margin: 0
}

.time-title {
    color: #4a5361;
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
    margin: 0;
}

.im {
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

.yesterday-number {
    background-color: #F1E8F4;
    color: #A264BB;
    border-radius: 8px;
    padding: 0.2rem 1rem;
    margin-left: 0.5rem;
    white-space: nowrap;
}

.positive {
    color: #44ca2e;
}

.negative {
    color: #EF585D;
}

.stats-highlight {
    font-size: 32px;
    color: #7a219e;
    font-weight: 700;
    padding: 0;
    margin: 0;
}

.component-card {
    min-width: 250px;
    display: flex;
    flex-direction: column;
    gap: 16px;
    background-color: #fefefe;
    padding: 24px;
    flex: 1;
    height: 100%;
}

.component-card-mobile {
    background-color: #fefefe;
    padding: 1rem;
    flex: 1;
    height: 100%;
}
</style>