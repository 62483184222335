<script>
import { useI18n } from 'vue-i18n'

export default {
    name: 'StreakComponentMobile',
    setup() {
		const { locale } = useI18n({ useScope: 'global' })
		return {
			locale
		}
	},
	data() {
		return {
			daysMap: new Map([
				['Mon', 'Mo'],
				['Tue', 'Di'],
				['Wed', 'Mi'],
				['Thu', 'Do'],
				['Fri', 'Fr'],
				['Sat', 'Sa'],
				['Sun', 'So']
			])
		}
	},
    props: {
        days: {
            type: Array,
            required: true
        },
        streak: {
            type: Number
        }
    },
    methods: {
        displayDayName(day) {
            if (this.locale == 'en') return day
            return this.daysMap.get(day)
        }
    },
    computed: {
        dayOfWeek() {
            let day = new Date().getDay()
            if (day === 0) return 6
            return day - 1
        },
        hasStudiedToday() {
            let dayToCheck = this.dayOfWeek
            if (this.days[dayToCheck].studied) return true
            return false
        }
    }
}
</script>

<template>
    <div class="streak-container border" id="streakComponentMobile">
        <div class="top">
            <img src="../../assets/Dashboard/brain.svg" alt="" height="100">
            <div>
                <h3 class="streak-text"><span style="color: #7a219e;">{{ (streak == 1) ? (streak + " " +
                    $t("message.dashboard.day")) : (streak + " " + $t("message.dashboard.days")) }} </span> {{
                            $t('message.dashboard.studyingFor2') }}</h3>
                <p class="streak-subtext">{{ $t("message.dashboard.study") }} {{ hasStudiedToday ?
                    $t("message.dashboard.tomorrow") : $t("message.dashboard.today") }} {{
                        $t("message.dashboard.toContinue") }}</p>
            </div>
        </div>
        <div class="days-container" v-if="days.length !== 0">
            <div v-for="(day, index) in days" :key="index" class="day-card"
                :class="day.studied ? 'studied' : (index === dayOfWeek) ? 'today-not-yet' : 'not'">
                <div class="day-name">
                    {{ displayDayName(day.day) }}
                </div>
            </div>
        </div>
    </div>
</template>

<style scoped>
.streak-text {
    color: #4a5361;
    padding: 0;
    margin-bottom: 0;
    font-size: 1.2rem;
}

.streak-subtext {
    color: #9a9ea6;
}

.top {
    display: flex;
    gap: 1rem;
}

.streak-container {
    display: flex;
    flex-direction: column;
    padding: 1rem;
    background-color: #ebdaf4;

}

.today-not-yet {
    border: 1px solid #7a219e;
    background-color: white !important;
    color: #7a219e;
}


.studied {
    background-color: #7a219e !important;
    color: white;
}

.not {
    background-color: white !important;
    border: 1px solid #9a9ea6;
    color: #4a5361;
}

.day-name {
    display: flex;
    justify-content: center;
    font-weight: 600;
    font-size: 1rem;
}



.day-card {
    display: flex;
    flex-direction: column;
    border-radius: 8px;
    background-color: rgba(240, 240, 240, 0.5);
    backdrop-filter: blur(5px);
    padding: 1rem 0;
}

.days-container {
    display: grid;
    margin-top: 1rem;
    gap: 0.5rem;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
}
</style>