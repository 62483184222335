<script>
import { useDeckStore, useWindowSizeStore } from '../../store/store'
import { useRoute } from 'vue-router'
import { toast } from 'vue3-toastify'

export default {
    name: "NavbarNavigation",
    setup() {
        const deckStore = useDeckStore()
        const windowSizeStore = useWindowSizeStore()
        const route = useRoute()

        return {
            deckStore,
            windowSizeStore,
            route,
        }
    },
    methods: {
        checkNavigate(e) {
            if (e.target.tagName !== 'SPAN') return
            toast(this.$t('message.leftNav.noDecksToast'))
        },
        openFeedback() {
            this.windowSizeStore.toggleFeedback()
        }
    },
    computed: {
        currentPath() {
            return this.route.path.split('/')[1]
        }
    }

}
</script>

<template>
    <div class="buttons">
        <router-link to="/create" style="text-decoration: none">
            <button class="primaryButton nav-create-button">
                {{ $t('message.leftNav.createNewFlashcards') }}
            </button>
        </router-link>
        <router-link class="button" to="/" :class="currentPath === 'dashboard' ? 'active' : ''">
            <img class="nav-icon" src="../../assets/homeIcon.svg" />
            {{ $t('message.leftNav.homeButton') }}
        </router-link>
        <component :is="(deckStore.decks && deckStore.decks.length != 0) ? 'router-link' : 'span'" to="/Decks"
            class="button" :class="currentPath === 'Decks' || currentPath === 'decks' ? 'active' : ''" :disabled="true"
            @click="checkNavigate">
            <img class="nav-icon" src="../../assets/decksIcon.svg" />
            {{ $t('message.leftNav.decksButton') }}
        </component>
        <router-link to="/shop" class="button" :class="currentPath === 'shop' ? 'active' : ''">
            <img class="nav-icon" src="../../assets/tokensIcon.svg" />
            {{ $t('message.leftNav.tokensButton') }}
        </router-link>
        <button class="button" @click="openFeedback">
            <img class="nav-icon" src="../../assets/feedbackIcon.svg" />
            {{ $t('message.leftNav.feedbackButton') }}
        </button>
    </div>
</template>


<style scoped>
.nav-icon {
    height: 24px;
    width: 24px;
}

.button:hover img {
    filter: brightness(0) contrast(100%) invert(20%) sepia(63%) saturate(2517%) hue-rotate(267deg) brightness(93%) contrast(104%);
}

.active img {
    filter: brightness(0) contrast(100%) invert(20%) sepia(63%) saturate(2517%) hue-rotate(267deg) brightness(93%) contrast(104%);
}

.active {
    color: #7a219e;
    background-color: #f6f1f8;
}

.nav-create-button {
    font-size: 16px;
    padding: 20px 24px;
    border-radius: 8px;
    width: 100%;
    margin-bottom: 1rem;
    text-decoration: none;
}

.buttons {
    display: flex;
    flex-direction: column;
    gap: 5px;
    margin-bottom: 1rem;
}

.button {
    width: 100%;
}
</style>
