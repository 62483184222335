<script>
export default {
    name: 'StudyingFinished',
    props: {
        studySession: {
            type: Boolean,
            default: false
        }
    },
    emits: ['session-done'],
    methods: {
        initConfetti() {
            confetti({
                particleCount: 100,
                spread: 70,
                origin: { y: 0.6 },
                colors: ["#7A219E"],
            });
        },
        async handleDone() {
            if (this.studySession) {
                this.$emit('session-done')
            } else {
                this.$router.push('/')
            }
        }
    },
    mounted() {
        this.showAnimation = true
        const script = document.createElement('script');
        script.src = 'https://cdn.jsdelivr.net/npm/tsparticles-confetti@2.11.0/tsparticles.confetti.bundle.min.js';
        script.async = true;
        script.onload = () => {
            this.initConfetti();
        };
        document.head.appendChild(script);
    }
}
</script>

<template>
    <div class="celebration-container" id="studyingFinished">
        <div>
            <img src="../../assets/StudyPage/congratulations.svg" alt="Congratulations" width="235"
                class="celebration-image">
        </div>
        <p v-if="!studySession" class="session-done">{{ $t('message.studyPage.done') }}</p>
        <p v-else class="session-done">{{ $t('message.studyPage.sessionDone') }}</p>
        <button class="celebration-button" @click="handleDone">
            <span v-if="!studySession">
                {{ $t('message.studyPage.learnOther') }}
            </span>
            <span v-else>
                {{ $t('message.studyPage.finishSession') }}
            </span>
        </button>
    </div>
</template>

<style scoped>
.session-done {
    color: rgb(110, 117, 128);
    font-size: 16px;
    text-align: center;
}

.celebration-container {
    margin: 36px auto;
    background-color: white;
    padding: 32px 24px;
    width: 50%;
    min-width: 300px;
    min-height: 50%;
    display: grid;
    place-items: center;
    border: 1px solid #f1e8f4;
    border-radius: 8px;
}

.celebration-image {
    margin: 1rem auto;

}


.celebration-button {
    background-color: #7A219E;
    color: white;
    padding: 11px 24px;
    border-radius: 8px;
    border: 1px solid #F1E8F4;
    font-weight: 700;
    font-size: 16px;
    line-height: 1;
}

.celebration-button:hover {
    background-color: #944CB0;
}
</style>