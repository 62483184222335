<script>
import RichTextEditor from '../common/RichTextEditor.vue'

export default {
    data() {
        return {
            updatedQuestion: '',
            updatedAnswer: '',
        };
    },
    props: {
        question: {
            type: String,
            required: true
        },
        answer: {
            type: String,
            required: true
        },
    },
    mounted() {
        this.updatedAnswer = this.answer
        this.updatedQuestion = this.question
    },
    methods: {
        changeAnswer(value) {
            this.updatedAnswer = value
        },
        changeQuestion(value) {
            this.updatedQuestion = value
        },
        saveChanges() {
            this.$emit('save-changes', { question: this.updatedQuestion, answer: this.updatedAnswer });
        }
    },
    emits: ['save-changes'],
    components: { RichTextEditor }
}
</script>

<template>
    <div class="edit-container" id="editCard">
        <p class="answer-question-title">Question:</p>
        <RichTextEditor :model-value="question" max-editor-height="20" @content-changed="changeQuestion" usage="question"/>
        <p class="answer-question-title">Answer:</p>
        <RichTextEditor :model-value="answer" max-editor-height="20" @content-changed="changeAnswer" usage="answer"/>
        <button @click="saveChanges" class="save-button" :disabled="updatedQuestion == '<p></p>' || updatedAnswer == '<p></p>'">
            {{ $t('message.studyPage.saveButton') }}
        </button>
    </div>
</template>

<style scoped>
.save-button {
    background-color: #7A219E;
    color: white;
    margin-top: 1rem;
    width: 100%;
    min-height: 80px;
    padding: 10px 24px;
    border-radius: 8px;
    font-size: 16px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 700;
}

.answer-question-title {
    margin: 1rem 0 0 0;
    padding: 0;
    font-size: 12px;
    color: #6E75A1;
}

.color-picker {
    width: 40px;
    height: 40px;
    padding: 5px;
}

.edit-container {
    background-color: white;
    border-radius: 8px;
    padding: 1rem 2rem;
    border: 1px solid #F1E8F4;

}

.editor {
    border: 1px solid black;
}
</style>