<script>
export default {
    name: "LoadingComponent",
}
</script>

<template>
    <div class="loader-container-study" id="loadingComponent">
        <div class="loader-card">
            <span class="loader">
                L <img src="../../assets/s.svg" alt="Study flash logo loading" class="animated-logo" width="26" height="29">
                ading...</span>
        </div>
    </div>
</template>

<style scoped>
.loader-container-study {
    width: 100%;
    background-color: #FBF8FC;
    display: grid;
    place-items: center;
    margin-top: 15%;
    min-width: 330px;
}

.loader-card {
    display: grid;
    place-items: center;
    background-color: white;
    padding: 2rem 2.5rem;
    border: 1px solid #F1E8F4;
    border-radius: 8px;
    white-space: nowrap;
}

.animated-logo {
    animation: rotation 1.2s linear infinite;
}

.loader {
    display: inline-block;
    font-size: 48px;
    font-family: Arial, Helvetica, sans-serif;
    font-weight: bold;
    color: #7A219E;
    position: relative;
    margin: 0 auto;
}

.loader::before {
    content: '';
    position: absolute;
    left: 32px;
    bottom: 3px;
    width: 45px;
    height: 45px;
    border-radius: 50%;
    border: 5px solid #7A219E;
    border-bottom-color: #FFF;
    box-sizing: border-box;
    animation: rotation 0.6s linear infinite;
}

@keyframes rotation {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}
</style>