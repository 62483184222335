<script>
import { useWindowSizeStore } from '../../store/store.js'


export default {
    setup() {
        const windowSizeStore = useWindowSizeStore()

        return {
            windowSizeStore
        }
    },
    props: {
        deckInfo: {
            type: Array,
            required: true
        },
        editMode: {
            type: Boolean,
            required: true
        },
        newCount: {
            type: Number,
            required: true
        },
        learnCount: {
            type: Number,
            required: true
        },
        reviewCount: {
            type: Number,
            required: true
        },
        total: {
            type: Number,
            required: false
        },
        remaining: {
            type: Number,
            required: false
        },
        isCustomStudySession: {
            type: Boolean,
            default: false
        }
    },
    methods: {
        emitEdit() {
            this.$emit('toggle-edit')
        },
        emitDelete() {
            this.$emit('delete-card')
        }
    },
    emits: ['toggle-edit', 'delete-card']
}
</script>

<template>
    <div>
        <div class="nav-container" :class="!windowSizeStore.isDesktop ? 'centered-nav' : ''" id="studyPageNav">
            <router-link to="/dashboard" class="nav-item">
                <img src="../../assets/logo.svg" alt="StudyFlash Logo" :width="windowSizeStore.isDesktop ? 183.3 : 145">
            </router-link>
            <div class="deck-info nav-item" v-if="windowSizeStore.isDesktop">
                <p class="card-numbering" v-if="!isCustomStudySession">
                    <span style="color: blue; cursor: pointer; font-weight: 700;" title="New Cards">{{ newCount
                        }}</span> /
                    <span style="color: red; cursor: pointer; font-weight: 700;" title="Learning Cards">{{ learnCount
                        }}</span> /
                    <span style="color: green; cursor: pointer; font-weight: 700;" title="Review Cards">{{ reviewCount
                        }}</span>
                </p>
                <p class="card-numbering" v-else>
                    <span style="color: #4a5361; cursor: pointer; font-weight: 700;" title="Remaining cards">
                        {{ remaining }}
                    </span>
                    /
                    <span style="color: #4a5361; cursor: pointer; font-weight: 700;" title="Starting cards">
                        {{ total }}
                    </span>
                </p>
                <span class="ancestor-info" v-for="(deck, index) in deckInfo" :key="index">
                    {{ deck }}{{ index < deckInfo.length - 1 ? ', ' : '' }} </span>
            </div>
            <div class="actions nav-item" v-if="windowSizeStore.isDesktop">
                <button class="edit-button nav-button" @click="emitEdit" :disabled="editMode">
                    {{ $t('message.studyPage.buttonEdit') }}
                </button>
                <router-link to="/dashboard" class="router-link">
                    <button class="finish-button nav-button">
                        {{ $t('message.studyPage.buttonFinish') }}
                    </button>
                </router-link>
            </div>
        </div>
        <div class="mobile-nav" v-if="!windowSizeStore.isDesktop">
            <div class="mobile-deck-info">
                <p class="remaining-cards">
                    <span title="Remaining cards">{{ remaining }}</span> / <span title="Starting card number">{{ total
                        }}</span>
                </p>
                <span class="mobile-ancestor-info" v-for="(deck, index) in deckInfo" :key="index">
                    {{ deck }}{{ index < deckInfo.length - 1 ? ', ' : '' }} </span>
            </div>
            <div class="mobile-actions">
                <button @click="emitEdit" class="mobile-button">
                    <fa-icon icon="fa-solid fa-pencil" class="small-icon"></fa-icon>
                </button>
                <button @click="emitDelete" class="mobile-button">
                    <fa-icon icon="fa-sold fa-trash" class="small-icon"></fa-icon>
                </button>
                <router-link to="/dashboard" class="router-link">
                    <button :disabled="editMode" class="mobile-button">
                        <fa-icon icon="fa-solid fa-x" class="small-icon" style="color: #7a219e;"></fa-icon>
                    </button>
                </router-link>
            </div>
        </div>
    </div>
</template>

<style scoped>
.remaining-cards {
    font-size: 14px;
    color: #4a5361;
    margin: 0;
    font-weight: 600;
}

.small-icon {
    height: 20px;
    color: #9a9ea6;
}

.router-link {
    cursor: pointer;
}

.card-numbering {
    padding: 0;
    margin: 0;
    font-size: 16px;
    font-weight: 500;
    line-height: 1.4;
    color: #212121;
}

a {
    display: grid;
    place-items: center;
    text-decoration: none;
}

.nav-container {
    width: 100%;
    display: flex;
    flex-direction: row;
    background-color: white;
    justify-content: space-between;
    align-items: center;
    border: 1px solid #F1E8F4;
    padding: 12px 28px;
    height: 76px;
    box-shadow: rgba(242, 233, 245, 0.96) 0px 2px 13px 0px;
}

.mobile-button {
    background-color: transparent;
    padding: 4px;
}

.mobile-button:hover {
    color: #7A219E;
}

.nav-item {
    flex: 0 1 auto;
}

.nav-item:nth-child(2) {
    margin: 0 auto;
}

.mobile-nav {
    background-color: #FBF8FC;
    display: flex;
    justify-content: space-between;
    padding: 16px 16px 0 16px;

}

.mobile-deck-info {
    text-align: left;
}

.mobile-actions {
    display: flex;
    gap: 8px;
    /* padding-right: 28px; */
}

.mobile-ancestor-info {
    font-weight: 500;
    font-size: 12px;
    color: #9a9ea6;
}

.centered-nav {
    justify-content: center;
    height: 64px;
    box-shadow: rgba(242, 233, 245, 0.96) 0px 2px 13px 0px;
}

.actions {
    display: flex;
    gap: 16px;
}

.nav-button {
    padding: 11px 24px;
    border-radius: 8px;
    border: 1px solid #F1E8F4;
    font-weight: 700;
    font-size: 14px;
    line-height: 1.43;
    box-shadow: 0px 7px 30px -10px rgba(122, 33, 158, 0.1);
}

.edit-button {
    color: #7A219E;
    background-color: white;
}

.edit-button:hover {
    background-color: #F6F1F8;
}

.finish-button {
    background-color: #7A219E;
    color: white;
}

.finish-button:hover {
    background-color: #944CB0;
}

.deck-info {
    text-align: center;
}

.ancestor-info {
    font-weight: 500;
    font-size: 12px;
    color: #9A93A6;
    overflow-wrap: break-word;
}
</style>