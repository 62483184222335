<script>
import { useUserStore, useWindowSizeStore } from '../../store/store'
import * as Sentry from "@sentry/vue";

export default {
    name: "ErrorBoundary",
    setup() {
        const windowSizeStore = useWindowSizeStore()
        const userStore = useUserStore()

        return {
            windowSizeStore,
            userStore
        }
    },
    data() {
        return {
            hasError: false,
            errorMessage: "",
            fullError: ""
        }
    },
    methods: {
        reportToSentry(error, info) {
            Sentry.getCurrentScope().setLevel("fatal");

            
            Sentry.captureException(error, {
                extra: {
                    info: info,
                    userId: this.userStore.user?.id,
                    userEmail: this.userStore.user?.email
                }
            })
        },
        async reload() {
            window.location.reload()
        },
        async reachOut() {
            let emailAddress = 'team@studyflash.ch';
            let subject = 'Unexpected error feedback!';
            let body = `
Hi Studyflash team,
Something went wrong and I need some help!

    <<Please explain the issue you are facing here>>

Best regards,
<<Your name>>

Error: 
${this.errorMessage}
`

            let mailtoLink = 'mailto:' + encodeURIComponent(emailAddress) +
                '?subject=' + encodeURIComponent(subject) +
                '&body=' + encodeURIComponent(body);

            window.location.href = mailtoLink;
        }
    },
    errorCaptured(error, vm, info) {
        this.reportToSentry(error, info)
        this.hasError = true
        this.errorMessage = error
        return false
    }
}
</script>

<template>
    <div v-if="hasError" :class="windowSizeStore.isDesktop ? 'error-wrapper' : 'error-wrapper-mobile'">
        <h2 :class="windowSizeStore.isDesktop ? 'desktop-h2' : ''">{{ $t('message.errorBoundary.title') }}</h2>
        <p :class="windowSizeStore.isDesktop ? 'desktop-p' : ''">{{ $t('message.errorBoundary.p11') }}<span
                class="url-like" @click="reload">{{ $t('message.errorBoundary.p12') }}</span>{{
                    $t('message.errorBoundary.p13') }}<a href="/logout" class="url-like">{{ $t('message.errorBoundary.p14')
                }}</a></p>
        <p :class="windowSizeStore.isDesktop ? 'desktop-p' : ''">{{ $t('message.errorBoundary.p21') }}<span
                @click="reachOut" class="url-like">{{ $t('message.errorBoundary.p22') }}</span></p>
    </div>
    <slot v-else></slot>
</template>

<style scoped>
.desktop-h2 {
    font-size: 22px;
}

.desktop-p {
    font-size: 16px;
}

.error-wrapper {
    border: 1px solid #f1e8f4;
    border-radius: 8px;
    padding: 16px 36px;
    background-color: white;
    width: max-content;
    max-width: 100%;
    margin: 5rem auto;
}

.error-wrapper-mobile {
    border: 1px solid #f1e8f4;
    border-radius: 8px;
    padding: 8px 12px;
    background-color: white;
    width: max-content;
    max-width: 95%;
    margin: 5rem auto;

}

.url-like {
    text-decoration: none;
    color: #7a219e;
    cursor: pointer;
    font-weight: 700;
}
</style>