<script>
import UserInfo from './UserInfo.vue';
import CONSTANTS from '@/common/constants.js'

export default {
	components: {
		UserInfo,
	},
	methods: {
		openSettings() {
			this.$emit('open-settings');
		}
	},
	data() {
		return {
			showHeader: false,
			featureOsUrl: CONSTANTS.FEATURE_OS_URL,
			headerStyle: {
				height: CONSTANTS.DESKTOP_HEADER_HEIGHT + 'px'
			}
		}
	},
	emits: ['open-settings']
};
</script>

<template>
	<div class="header" id="header" :style="headerStyle">
		<a :href="featureOsUrl" target="_blank" style="text-decoration: none;">
			<button class="feature-os-button" id="featureOs">
				<fa-icon icon="fa-sold fa-rocket" class="icon"></fa-icon>
				{{ $t('message.header.seeWhatsNew') }}
			</button>
		</a>
		<UserInfo @open-settings="openSettings" />
	</div>
</template>

<style>
.feature-os-button {
	border-radius: 8px;
	border: 1px solid #f1e8f4;
	color: #7a219e;
	padding: 12px 24px;
	height: 100%;
	font-weight: 700;
	font-size: 14px;
	line-height: 1;
	display: flex;
	justify-content: center;
	align-items: center;
	gap: 10px;
}

.feature-os-button:hover {
	background-color: #f6f1f8;
}

.icon {
	height: 24px;
}

.header {
	justify-content: flex-end;
	gap: 16px;
}
</style>
