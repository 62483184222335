import { supabase } from "../common/database";
import { v4 as uuidv4 } from 'uuid';

export const CustomStudySessionService = {
    async fetchCustomStudySessions() {
        return await supabase.from('custom_study_sessions').select("*").eq('_deleted', false)
    },
    async createStudySession(session) {
        if (!session.id) {
            session.id = uuidv4();
        }
        return await supabase.from('custom_study_sessions').insert(session).select()
    },
    async bulkCreateReferences(refArray) {
        return await supabase.from('session_card_references').insert(refArray).select()
    },
    async getFlashcardsForSession(sessionId) {
        return await supabase.rpc("get_session_card_references", { session_id: sessionId })
    },
    async updateReference(newState) {
        const dto = {
            card_id: newState.card_id,
            id: newState.id,
            next_showing: newState.next_showing,
            _deleted: newState._deleted,
        }
        return await supabase.from('session_card_references').update(dto).eq('id', dto.id)
    },
    async deleteSession(sessionId) {
        return await supabase.from('custom_study_sessions').delete().eq('id', sessionId)
    },
    async getStudySessionInfo(sessionId) {
        return await supabase.from('custom_study_sessions').select('*').eq('id', sessionId)
    }
}