<script>
import { ref, onBeforeMount, onMounted, computed } from 'vue'
import { supabase } from '../common/database'
import { router } from '../router'
import { useRoute } from 'vue-router'
import { toast } from 'vue3-toastify'
import { useUserStore, useWindowSizeStore } from '../store/store.js'
import { useI18n } from 'vue-i18n'
import backgroundImgEn from '@/assets/bg_english.svg'
import backgroundImgDe from '@/assets/bg_german.svg'
import { captureException } from "@sentry/vue";

//TODO: refactor to proper options / composition api
// extract arrays into constants
// take a look into questionable computed properties for performance
export default {
	setup() {
		const route = useRoute()
		const windowSizeStore = useWindowSizeStore()
		const userStore = useUserStore()
		const signUpEmail = ref('')
		const signInEmail = ref('')
		const { locale, t } = useI18n({ useScope: 'global' })
		const signUpPassword = ref('')
		const confirmsignUpPassword = ref('')
		const signInPassword = ref('')
		const isSignUp = ref(false)
		const tocAgreement = ref(false)
		const isSignUpPasswordVisible = ref(false)
		const isSigninPasswordVisible = ref(false)
		const privacyPolicyURL = computed(
			() => 'https://studyflash.ai/privacy_policy' + (locale.value === 'de' ? '?lang=de_de' : '')
		)
		const termsOfServiceURL = computed(
			() => 'https://studyflash.ai/tos' + (locale.value === 'de' ? '?lang=de_de' : '')
		)
		const backgroundImgUrl = computed(() =>
			locale.value === 'de' ? backgroundImgDe : backgroundImgEn
		)
		function toggleSignUp() {
			isSignUp.value = !(isSignUp.value)
		}
		async function handleLanguageChange(data) {
			locale.value = data
		}
		onBeforeMount(() => {
			if (route.query.referral) {
				localStorage.setItem('referral', route.query.referral)
			}
		})
		onMounted(() => {
			let debug = localStorage.getItem('debug-logout')
			if (debug) {
				window.location.reload()
				localStorage.removeItem('debug-logout')
			}
			let date = userStore.accountDeletedDate
			if (!date) return
			notifyUserOfAccountStatus(date)
			userStore.setAccountDeletedDate(null)
		})

		const notifyUserOfAccountStatus = (dateOfDeletion) => {
			let currentDate = new Date()
			let deletedDate = new Date(dateOfDeletion)
			let timeDiff = currentDate.getTime() - deletedDate.getTime()
			let daysDiff = timeDiff / (1000 * 60 * 60 * 24)
			if (daysDiff > 30) {
				toast(
					`${t('message.signInPage.accPermanentlyDeleted')}\n${deletedDate.toLocaleDateString()}`
				)
			} else {
				toast(
					`${t('message.signInPage.accTemporaryDeleted1')}\n${deletedDate.toLocaleDateString()}${t('message.signInPage.accTemporaryDeleted2')}`
				)
			}
		}

		const handleGoogleLogin = async () => {
			try {
				const { data, error } = await supabase.auth.signInWithOAuth({
					provider: 'google',
				})
				if (error) throw error
			} catch (error) {
				toast(t('message.signInPage.unsuccessfulLogin'))
				captureException(error)
			}
		}

		const handleAzureLogin = async () => {
			try {
				const { data, error } = await supabase.auth.signInWithOAuth({
					provider: 'azure',
					options: {
						scopes: 'email',
					},
				})
				if (error) throw error
			} catch (error) {
				console.error(error)
				captureException(error)
			}
		}

		return {
			signUpEmail,
			signInEmail,
			signUpPassword,
			signInPassword,
			confirmsignUpPassword,
			isSignUp,
			handleGoogleLogin,
			tocAgreement,
			isSignUpPasswordVisible,
			isSigninPasswordVisible,
			router,
			windowSizeStore,
			handleLanguageChange,
			privacyPolicyURL,
			termsOfServiceURL,
			handleAzureLogin,
			backgroundImgUrl,
			toggleSignUp
		}
	},
}
</script>


<template>
	<div class="global-container" id="signUp">
		<div class="centering-container">
			<div class="logo_header">
				<img src="../assets/logo.svg" alt="Logo" class="logo" />
				<div class="language_selector">
					<img src="../assets/english.png" alt="English" class="language_icon"
						@click="handleLanguageChange('en')" />
					<img src="../assets/german.png" alt="German" class="language_icon"
						@click="handleLanguageChange('de')" />
				</div>
			</div>
			<div class="content">
				<h2 id="sign-header" class="welcome-title">{{ $t('message.signInPage.heading') }}</h2>
				<p>{{ $t('message.signInPage.subtitle') }}</p>
				<button class="google-btn" @click="handleGoogleLogin">
					<img src="../assets/googleIcon.svg" />
					{{ $t('message.signInPage.continueWithGoogle') }}
				</button>
				<div class="divider">
					<div class="divider_line"></div>
					<p>{{ $t('message.signInPage.or') }}</p>
					<div class="divider_line"></div>
				</div>
				<button class="google-btn" @click="handleAzureLogin">
					<fa-icon icon="fa-solid fa-user-shield" class="icon"></fa-icon>
					{{ $t('message.signInPage.continueWithCredentials') }}
				</button>
			</div>
			<div class="tos-text">
				<span>{{ $t("message.onboardingPage.toc1") }}</span>
				<a href="https://studyflash.ai/tos" target="_blank">{{ $t("message.onboardingPage.toc2") }}</a>
				<span>{{ $t("message.onboardingPage.toc3") }}</span>
				<a href="https://studyflash.ai/privacy_policy" target="_blank">{{
					$t("message.onboardingPage.toc4") }}</a>
				<span>{{ $t("message.onboardingPage.toc5") }}</span>
			</div>
		</div>
		<div class="sign_background_wrapper">
			<img class="sign_background" :src="backgroundImgUrl" />
		</div>
	</div>
</template>


<style scoped>
.tos-text {
	color: #6e7580;
}

.sign_background_wrapper {
	display: flex;
	width: 50%;
	background: radial-gradient(rgb(255, 255, 255) 0%, rgb(241, 232, 244) 100%);
	justify-content: flex-end;
	align-items: center;
	min-height: 100vh;
}

.sign_background {
	max-width: 90%;
	max-height: 100dvh;
}

.centering-container {
	flex: 1;
	padding: 1% 7%;
	justify-content: space-between;
	display: flex;
	flex-direction: column;
	max-width: 70%;
	margin: 0 auto;
}

@media screen and (max-width: 1050px) {
	.sign_background_wrapper {
		display: none;
	}
}

@media screen and (max-width: 650px) {
	.global-container {
		padding: 5% 5%;
		max-width: 100%;
	}

	.sign_background_wrapper {
		display: none;
	}

	.centering-container {
		padding: 0;
		max-width: 95%;
		margin: 0;
	}
}


.welcome-title {
	font-size: 24px;
	font-weight: 800;
	text-transform: uppercase;
	color: #4a5361;
	width: 100%;
	margin: 0;
}

.global-container {
	display: flex;
	box-sizing: border-box;
	height: 100dvh;
}

.logo {
	width: 194px;
	height: 60.066px;
	flex-shrink: 0;
}

.google-btn {
	display: flex;
	width: 100%;
	align-self: stretch;
	padding: 10px 20px 10px 28px;
	justify-content: center;
	align-items: center;
	gap: 8px;
	border-radius: 8px;
	border: 1px solid var(--Gray-line, #d7d8db);

	color: var(--purple-100, #7a219e);
	text-align: center;

	font-family: Manrope;
	font-size: 16px;
	font-style: normal;
	font-weight: 700;
	line-height: 22px;
	margin-bottom: 24px;
}

.divider {
	display: flex;
	align-items: center;
	margin-bottom: 24px;
}

.divider p {
	all: initial;
	color: var(--text-light-gray, #9a9ea6);
	font-family: Manrope;
	font-size: 16px;
	font-style: normal;
	font-weight: 500;
	line-height: 24px;
	letter-spacing: 0.08px;
}

.divider_line {
	height: 1px;
	margin: 0 8px;
	flex-grow: 1;
	background: #d7d8db;
	margin: 0px 10px;
}

.language_icon {
	width: 24px;
	height: 24px;
	flex-shrink: 0;
	border-radius: 12px;
}

.language_icon:hover {
	cursor: pointer;
}

.logo_header {
	display: flex;
	justify-content: space-between;
	align-items: center;
	width: 100%;
}

.language_selector {
	display: flex;
	justify-content: flex-start;
	gap: 5px;
}

#sign-header {
	color: rgb(33 33 33);
	font-size: 24px;
	font-weight: 800;
	font-family: Manrope;
	display: flex;
	width: 100%;
	justify-content: space-between;
	align-items: center;
}
</style>
