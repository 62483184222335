<script setup>
import { onMounted, ref, computed, onBeforeMount, watch, onUnmounted } from 'vue'
import { supabase } from './common/database'
import LeftNavbar from './components/layout/LeftNavbar.vue'
import MobileNavbar from './components/layout/MobileNavbar.vue'
import Header from './components/layout/Header.vue'
import Settings from './components/layout/Settings.vue'
import Referral from './components/layout/Referral.vue'
import FeedbackPopup from './components/layout/FeedbackPopup.vue'
import 'typeface-manrope'
import {
	useWindowSizeStore,
	useUserStore,
	useDeckStore,
	initStores,
} from './store/store'
import { useRoute } from 'vue-router'
import { useI18n } from 'vue-i18n'
import { useRouter } from 'vue-router'
import ErrorBoundary from './components/common/ErrorBoundary.vue'
import { toast } from 'vue3-toastify'
import GenericPopup from './components/common/GenericPopup.vue'
import { DeckService } from './services/deck.service'
import PromoterScorePopup from './components/common/PromoterScorePopup.vue'
import RatingScale from './components/common/RatingScale.vue'
import { captureException } from "@sentry/vue";
import LoadingState from './components/common/LoadingState.vue'

const devMode = import.meta.env.VITE_MODE === 'development' || import.meta.env.VITE_MODE === "UAT"
const maintenanceMode = import.meta.env.VITE_MAINTENANCE_MODE === 'true'
const { t } = useI18n()
const { locale } = useI18n({ useScope: 'global' })
const router = useRouter()
const route = useRoute()

const windowSizeStore = useWindowSizeStore()
const deckStore = useDeckStore()
const userStore = useUserStore()

const showNavbarAndHeader = ref(true)
const deckRating = ref(10)
const deckFeedback = ref('')


const notShowingNavbarAndHeaderPaths = [
	'/signin',
	'/signup',
	'/onboarding',
	'/reset-password',
	'/request-password-reset',
	'/study',
	"/maintenance"
]

const checkNavbarAndHeaderVisibility = (path) => {
	showNavbarAndHeader.value = !notShowingNavbarAndHeaderPaths.some((notShownPath) =>
		path.startsWith(notShownPath)
	)
}

onBeforeMount(() => {
	checkNavbarAndHeaderVisibility(router.currentRoute.value.path)
})

supabase.auth.onAuthStateChange(async (event, session) => {
	if (event !== 'SIGNED_IN') return
	checkIsAccountDeleted(session.user.email)
})

watch(
	() => route.hash,
	(hash) => {
		if (hash.startsWith('#access_token')) {
			router.replace({ hash: '' })
		}
	},
	{ immediate: true }
)
async function checkIsAccountDeleted(email) {
	let isDeleted = await userStore.checkIfAccountDeleted(email)
	if (!isDeleted) return
	router.push({ name: 'signup' })
}

function handleUnhandledPromises(e) {
	e.preventDefault()
	console.error(e)
	captureException(e)
	if (e.reason && e.reason.code !== 25) {
		toast.error(t('message.errorCodes.general.unhandledPromise'))
		console.error("Unhandled promise rejection:", e)
	}
}

function setLocale() {
	if (userStore.user) {
		locale.value = userStore.user.locale
		localStorage.setItem('locale', userStore.user.locale)
		return
	}
	if (localStorage.getItem('locale')) {
		locale.value = localStorage.getItem('locale')
	}
}

onMounted(async () => {
	if (!devMode) window.addEventListener('unhandledrejection', handleUnhandledPromises)
	window.addEventListener('resize', windowSizeStore.updateIsDesktop)

	const authResponse = await supabase.auth.getSession()
	if (!authResponse.data.session) {
		windowSizeStore.setGlobalLoading(false)
		return
	}

	windowSizeStore.setGlobalLoading(true)
	await initStores()
	windowSizeStore.setGlobalLoading(false)
	setLocale()
})

onUnmounted(() => {
	window.removeEventListener('resize', windowSizeStore.updateIsDesktop)
	window.removeEventListener('unhandledrejection', handleUnhandledPromises)
})

watch(
	() => router.currentRoute.value.path,
	(newPath) => {
		checkNavbarAndHeaderVisibility(newPath)
	}
)

async function rateDeck() {
	//TODO: transform into a store action
	const ratingResponse = await DeckService.rateDeck(deckRating.value, deckFeedback.value, deckStore.feedbackDeckId)
	if (ratingResponse.error) {
		console.error(ratingResponse.error)
		captureException(error)
		toast.error(t('message.errorCodes.general.deckRatingFailed'))
		return
	}
	deckStore.setFeedbackDeckId(null)
	deckRating.value = 10
	deckFeedback.value = ''
}

const updateSelectedOption = (newOption) => {
	deckRating.value = newOption
}

</script>

<template>
	<PromoterScorePopup v-if="userStore.askForPromoterScoreRating" />
	<GenericPopup :showPopup="deckStore.feedbackDeckId !== null" :submitAction="rateDeck"
		:cancelAction="() => deckStore.setFeedbackDeckId(null)" title="Feedback"
		@hide-popup="() => deckStore.setFeedbackDeckId(null)">
		<p>{{ $t('message.deckFeedback.welcome') }}</p>
		<RatingScale :selectedOption="deckRating" :updateSelectedOption="updateSelectedOption"
			:tooltipGood="$t('message.deckFeedback.excellent')" :tooltipBad="$t('message.deckFeedback.unusable')" />
		<p>{{ $t('message.deckFeedback.additionalFeedback') }}</p>
		<textarea name="" id="" v-model="deckFeedback" class="feedback-text-area"></textarea>
	</GenericPopup>
	<FeedbackPopup v-if="windowSizeStore.displayFeedback" />
	<Referral v-if="windowSizeStore.displayReferral" @close-referral="windowSizeStore.toggleReferral" />
	<Settings v-if="windowSizeStore.displaySettings" @close-settings="windowSizeStore.toggleSettings" />
	<div class="top-level-wrapper"
		v-if="router.currentRoute.value.path === '/signup' || maintenanceMode || !windowSizeStore.globalLoading && userStore.user">
		<LeftNavbar v-if="windowSizeStore.isDesktop && showNavbarAndHeader" />
		<span v-if="windowSizeStore.isDesktop" class="desktop-top-level-wrapper">
			<Header @open-settings="windowSizeStore.toggleSettings" v-if="showNavbarAndHeader"></Header>
			<div :class="showNavbarAndHeader ? 'desktop-router-container' : 'no-nav'">
				<ErrorBoundary v-if="!devMode">
					<router-view />
				</ErrorBoundary>
				<router-view v-else />
			</div>
		</span>
		<div v-else class="mobile-top-level-wrapper" id="page-wrapper">
			<MobileNavbar v-if="showNavbarAndHeader" />
			<ErrorBoundary v-if="!devMode">
				<router-view />
			</ErrorBoundary>
			<router-view v-else />
		</div>
	</div>
	<LoadingState v-else />

</template>

<style scoped>
.no-nav {
	height: 100vh;
	overflow-y: auto;
}

@media (max-width: 768px) {
	.no-nav {
		height: auto;
		min-height: 100vh;
		overflow-y: scroll;
	}
}

.desktop-router-container {
	padding-left: 3rem;
	height: calc(100vh - 92px);
	overflow-y: scroll;
}

.desktop-top-level-wrapper {
	width: 100%;
	height: 100%;
}

.mobile-top-level-wrapper {
	width: 100%;
	max-width: 100%;
	height: 100vh;
	overflow: auto;
}

.top-level-wrapper {
	height: 100%;
	padding: 0;
	background-color: #fbf8fc;
	display: flex;
	width: 100%;
}

.sub-slider-container {
	display: flex;
	justify-content: space-between;
	width: 100%;
	margin: 2rem 0rem;
}

.feedback-text-area {
	width: 100%;
	padding: 0.5rem;
	border-radius: 5px;
	border: 1px solid #f1e8f4;
	font-size: 1rem;
	margin-bottom: 0.5rem;
	height: 8rem;
	resize: none;
	color: #666;
}
</style>
