import { createClient } from '@supabase/supabase-js'

const supabaseUrl = import.meta.env.VITE_SUPABASE_URL
const supabaseAnonKey = import.meta.env.VITE_SUPABASE_ANON_KEY


// Custom fetch function with retry and cooldown
const customFetchWithRetry = async (url, options, retries = 3, cooldown = 1000) => {
    for (let i = 0; i < retries; i++) {
      try {
        // Attempt to fetch
        const response = await fetch(url, options);
        
        // Return the response if successful
        if (response.ok) {
          console.log(`Attempt ${i + 1} successful: ${response.statusText}`);
          return response;
        }
  
        // If response is not OK, log an error (optional)
        console.error(`Attempt ${i + 1} failed: ${response.statusText}`);
        
      } catch (error) {
        // Catch network errors and log them
        console.error(`Attempt ${i + 1} failed:`, error.message);
        console.error(url)
        console.error(options)
      }
  
      // Wait for cooldown period before retrying
      if (i < retries - 1) {
        console.log(`Waiting for ${cooldown}ms before retrying...`);
        await new Promise(resolve => setTimeout(resolve, cooldown));
      }
    }
  
    // After all retries fail, throw an error
    throw new Error(`Failed to fetch after ${retries} retries.`);
  };
  
  export const supabase = createClient(supabaseUrl, supabaseAnonKey,
    {
        global:{
            fetch: (url, options) => customFetchWithRetry(url, options),
        }
    }
    
)

