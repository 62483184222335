<script>
import { capitalizeFirstLetter } from '../../common/util'
import { useSubscriptionStore } from '../../store/store'
import { supabase } from '../../common/database'
import { toast } from 'vue3-toastify'
import { captureException } from "@sentry/vue";

export default {	
	name: 'SubscriptionTab',
	setup() {
		const subscriptionStore = useSubscriptionStore()

		return {
			subscriptionStore,
			capitalizeFirstLetter
		}
	},
	data() {
		return {
			loading: false
		}
	},
	methods: {
		async navigateToStripe() {
			this.loading = true
			const { data: customerPortalSession, error } = await supabase.functions.invoke('customer-portal')
			this.loading = false
			if (error) {
				console.error(error)
				captureException(error)
				toast.error(this.$t('message.errorCodes.settings.manageSubscriptionsFailed'))
				return
			}
			window.location.href = customerPortalSession.portalSessionUrl
		}
	},
	computed: {
		subscriptionType() {
			if (this.subscriptionStore.getSubscriptionStatus() === 2 || this.subscriptionStore.getSubscriptionStatus() === 5) {
				return this.$t(`message.settings.${this.subscriptionStore.subscription.type}`)
			}
			return this.$t(`message.settings.inactive`)
		}
	}
}
</script>

<template>
	<div>
		<h2 class="standard_text">
			{{ $t('message.settings.subscriptionTitle') }}
		</h2>
		<div class="sub-data">
			<p class="label">
				{{ $t('message.settings.currentSubscription') }}
			</p>
			<p class="info">
				{{ subscriptionType }}
			</p>
		</div>
		<div class="end"
			v-if="subscriptionStore.getSubscriptionStatus() === 2 || subscriptionStore.getSubscriptionStatus() === 5">
			<p class="label">
				{{ $t('message.settings.renewalDate') }}
			</p>
			<p class="info">
				{{
					new Date(subscriptionStore.subscription.end_date).toLocaleDateString()
				}}
			</p>
		</div>
	</div>
	<button class="manage-sub-button" @click="navigateToStripe" :disabled="loading">
		{{ $t('message.settings.manageSubscriptions') }}
	</button>
</template>

<style scoped>
.info {
	color: #7a219e;
}

.label {
	font-size: 16px;
	color: #4a5361;
}

.tab-title {
	font-size: 1.4rem;
	font-weight: 600;
	line-height: 1.43;
	color: #4a5361;
}

.manage-sub-button {
	background-color: #7a219e;
	color: white;
	font-weight: 700;
	font-size: 14px;
	padding: 8px 16px;
	border-radius: 8px;
	margin-top: auto;
	width: 100%;
}

.manage-sub-button:hover {
	background: #954cb1;
}
</style>