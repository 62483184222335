<script setup>
import { ref, computed } from 'vue'
import { useWindowSizeStore, useDeckStore, useUserStore, useStatisticsStore } from '../store/store'
import StreakComponent from '../components/dashboard/StreakComponent.vue'
import StreakComponentMobile from '../components/dashboard/StreakComponentMobile.vue'
import CardsStudied from '../components/dashboard/CardsStudied.vue'
import StudyTime from '../components/dashboard/StudyTime.vue'
import DeckPreviewTableRow from '../components/dashboard/DeckPreviewTableRow.vue'
import GenericPopup from '../components/common/GenericPopup.vue'
import { useRouter } from 'vue-router'
import { captureException } from '@sentry/vue'

const router = useRouter()
const windowSizeStore = useWindowSizeStore()
const statisticsStore = useStatisticsStore()
const deckStore = useDeckStore()
const userStore = useUserStore()
const isDesktop = computed(() => windowSizeStore.isDesktop)
const statisticsData = computed(() => {
	return statisticsStore.statisticsData
})
const decks = ref(deckStore.decks)

async function handleHideWelcomeMessage(e, shouldNavigate = false) {
	const profileUpdateResponse = userStore.hideWelcomeMessage()
	if (profileUpdateResponse.error) {
		captureException(profileUpdateResponse.error)
	}
	if (shouldNavigate) return
	router.push("/create")
}

function getUsernameFromEmail(email) {
	return email.split('@')[0]
}
</script>

<template>
	<div class="dashboard" id="dashboard">
		<GenericPopup
			:showPopup="userStore.user && !userStore.user.seen_welcome_message && userStore.user.subscription_user"
			:cancelAction="handleHideWelcomeMessage" :cancelText="$t('message.deckPage.cancel')" :isInformational="true"
			@hide-popup="() => handleHideWelcomeMessage(true)" title="" :isLarge="true"
			:hideActionButtons="true">
			<div class="popup-image-container">
				<img src="../assets/StudyPage/congratulations.svg" alt="Welcome image" width="140"
					class="welcome-image">
				<h2 class="popup-title">{{ $t('message.welcomeMessage.title') }}</h2>
			</div>
			<div class="popup-content">Hey {{ getUsernameFromEmail(userStore.user.email) }},</div>
			<p class="popup-content">{{ $t('message.welcomeMessage.thrilled') }}</p>
			<p class="popup-content">{{ $t('message.welcomeMessage.text1') }}<b>{{ $t('message.welcomeMessage.text2')
					}}</b>{{
						$t('message.welcomeMessage.text3') }}
			</p>
			<div class="popup-image-container">
				<button class="try-free-button" @click="handleHideWelcomeMessage">{{
					$t('message.welcomeMessage.tryFree') }}</button>
			</div>
		</GenericPopup>
		<div class="content">
			<div class="group_content">
				<h1 class="standard_text">{{ $t('message.dashboard.dashboard') }}</h1>
				<div class="outer-stats-container" v-if="statisticsData">
					<StreakComponent v-if="isDesktop" :days="statisticsData.days" :streak="statisticsData.streak" />
					<StreakComponentMobile v-else :days="statisticsData.days" :streak="statisticsData.streak" />
					<div :class="isDesktop ? 'stats-container' : 'stats-container-mobile'">
						<StudyTime :time="statisticsData.time" :isDesktop="isDesktop" />
						<CardsStudied v-if="isDesktop" :cards="statisticsData.cards" />
					</div>
				</div>
				<div class="card deck-shortcut-container">
					<div class="deck-table-header">
						<span :class="windowSizeStore.isDesktop ? 'table-subtitle' : 'table-subtitle-mobile'">{{
							$t("message.dashboard.flashcardReview") }}</span>
						<router-link to="/study" v-if="decks && decks.length !== 0">
							<button class="study-all-button">{{ $t('message.dashboard.studyAllButton') }}</button>
						</router-link>
					</div>
					<div class="scroll-container" v-if="decks && decks.length !== 0">
						<table class="deck-container-table">
							<thead class="table-header">
								<th :class="windowSizeStore.isDesktop ? 'name-header' : 'name-header-mobile'">
									{{ $t('message.dashboard.deckName') }}</th>
								<th v-if="windowSizeStore.isDesktop" class="data-header">{{ $t('message.dashboard.new')
									}}</th>
								<th v-if="windowSizeStore.isDesktop" class="data-header">{{
									$t('message.dashboard.learn') }}</th>
								<th class="data-header last-header">{{ $t('message.dashboard.due') }}</th>
							</thead>
							<tbody
								v-if="deckStore.newDeckTree.root.children && deckStore.newDeckTree.root.children.length != 0"
								style="overflow: scroll;">
								<DeckPreviewTableRow :decks="deckStore.newDeckTree.root.children" :depth="0" />
							</tbody>
						</table>
					</div>
					<div v-else class="no-decks">
						<img src="../assets/Dashboard/no_decks.svg" alt="No decks found" width="235" height="220">
						<p class="no-cards">
							{{ $t('message.dashboard.noDecks') }}
						</p>
						<router-link to="/create" style="text-decoration: none;">
							<button class="primaryButton create-button">{{ $t('message.dashboard.createNewFlashcards')
								}}</button>
						</router-link>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<style scoped>
.try-free-button {
	color: #7a219e;
	border: 1px solid #7a219e;
	border-radius: 8px;
	padding: 12px 16px;
	font-size: 16px;
	font-weight: 600;
}

.try-free-button:hover {
	background-color: #ddc7e6;
}

.popup-content {
	color: #4a5361;
}

.popup-title {
	color: #7a219e;
	font-size: 24px;
	line-height: 1.4;
	font-weight: 700;
}

.popup-image-container {
	width: 100%;
	display: flex;
	align-items: center;
	flex-direction: column;
}

.no-cards {
	line-height: 24px;
	font-size: 16px;
	color: #6e7580;
}

.create-button {
	font-size: 16px;
	padding: 20px 24px;
	border-radius: 8px;
	width: max-content;
	margin-bottom: 1rem;
}

.no-decks {
	height: 100%;
	width: 100%;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	gap: 24px;
	margin: 90px 0;
}

.deck-shortcut-container {
	width: 100%;
	margin-top: 24px;
	height: max-content;
	padding: 24px;
	overflow: hidden;
}

.outer-stats-container {
	flex-wrap: wrap;
	gap: 16px;
	display: flex;
	height: 100%;
}

.stats-container {
	flex: 1;
	display: flex;
	gap: 16px;
	min-width: 450px;
}

.stats-container-mobile {
	flex: 1;
	display: flex;
	gap: 2rem;
	flex-wrap: wrap;
}

.scroll-container {
	width: 100%;
	overflow-x: auto;
}

.table-subtitle {
	font-size: 24px;
	line-height: 32px;
	font-weight: 700;
	color: #4a5361;
}

.table-subtitle-mobile {
	font-size: 16px;
	color: #4a5361;
}

.deck-table-header {
	display: flex;
	justify-content: space-between;
	align-items: center;
}

.last-header {
	border-right: none !important;
}

.table-header {
	background-color: rgb(248, 244, 250);
}

.data-header {
	padding: 16px 24px;
	font-size: 16px;
	border-left: 1px solid rgb(241, 232, 244);
	border-right: 1px solid rgb(241, 232, 244);
	width: 5%;
	color: #4a5361;
}

.name-header {
	text-align: left;
	padding: 16px 24px;
	font-size: 16px;
	width: 85%;
	color: #4a5361;
}

.name-header-mobile {
	text-align: left;
	padding: 16px;
	font-size: 16px;
	width: 70%;
	color: #4a5361;
}

.deck-container-table {
	width: 100%;
	max-width: 100%;
	border-collapse: collapse;
	margin-top: 16px;
}

.study-all-button {
	background-color: white;
	border-radius: 8px;
	border: 1px solid rgb(241, 232, 244);
	color: #7a219e;
	padding: 7px 16px;
	font-size: 14px;
	font-weight: 700;
	line-height: 1.4;
	height: max-content;
}
</style>
