<script setup>
import { reactive, ref, onMounted } from 'vue'
import { useWindowSizeStore } from '../../store/store.js'
import { format, parseISO } from 'date-fns'
import DeckPreviewTableRow from './DeckPreviewTableRow.vue'
import { useRouter } from 'vue-router'
import { supabase } from '../../common/database.js'
import { toast } from 'vue3-toastify'
import { useI18n } from 'vue-i18n'
import { captureException } from "@sentry/vue";

const { t } = useI18n()
const windowSizeStore = useWindowSizeStore()
const $router = useRouter()
const cardCountMap = ref(new Map())
const loading = ref(true)

onMounted(async () => {
	let calculationFailed = false;
	const promises = props.decks.map(async (deck) => {
		try {
			const res = await supabase.rpc('get_flashcard_counts', { initial_id: deck.data.id });
			if (res.error) {
				console.error(res.error);
				calculationFailed = true;
				captureException(res.error);
			}
			if (!cardCountMap.value.has(deck.data.id)) {
				cardCountMap.value.set(deck.data.id, res.data[0]);
			}
		} catch (error) {
			console.error(error);
			calculationFailed = true;
			captureException(error);
		}
	});

	await Promise.all(promises);

	if (calculationFailed) {
		toast.error(t('message.errorCodes.dashboard.cardCountingFailed'));
	}

	loading.value = false;
});

const props = defineProps({
	decks: {
		type: Array,
		required: true,
	},
	depth: {
		type: Number,
		required: true,
	},
})

const displayChildren = reactive({})

const toggleChildren = (id) => {
	if (displayChildren[id]) {
		displayChildren[id] = !displayChildren[id]
	} else {
		displayChildren[id] = true
	}
}

function startStudy(deckId) {
	$router.push(`/study/${deckId}`)
}
</script>

<template>
	<template v-for="deck in decks" :key="deck.data.id">
		<tr :id="deck.data.id" class="table-row" @click="startStudy(deck.data.id)">
			<td :class="windowSizeStore.isDesktop ? 'table-data-row' : 'table-data-row-mobile'"
				:style="{ 'padding-left': depth * 30 + 'px' }">
				<div style="width: 24px; height: 24px">
					<img v-if="!displayChildren[deck.data.id] && deck.children.length > 0"
						@click.stop.prevent="toggleChildren(deck.data.id)"
						:class="windowSizeStore.isDesktop ? 'plus' : 'plus-mobile'" src="../../assets/plus.svg" />
					<img v-if="displayChildren[deck.data.id] && deck.children.length > 0"
						@click.stop.prevent="toggleChildren(deck.data.id)"
						:class="windowSizeStore.isDesktop ? 'plus' : 'plus-mobile'" src="../../assets/minus.svg" />
				</div>
				<p :style="windowSizeStore.isDesktop ? 'padding: 8px 16px; margin: 0' : 'padding: 8px; margin: 0;'
		">
					<span :class="windowSizeStore.isDesktop ? 'big-text' : 'big-text-mobile'">
						{{ deck.data.name }}<br />
					</span>
					<span :class="windowSizeStore.isDesktop ? 'small-text' : 'small-text-mobile'">
						{{ $t('message.dashboard.created') }}:</span>
					<span :class="windowSizeStore.isDesktop ? 'small-text' : 'small-text-mobile'"
						style="color: #6e7580">{{ deck.data.created_at ? format(parseISO(deck.data.created_at),
		'dd.MM.yyyy') : ''
						}}<br />
					</span>
					<span :class="windowSizeStore.isDesktop ? 'small-text' : 'small-text-mobile'">
						{{ $t('message.dashboard.flashcards') }}:</span>
					<span :class="windowSizeStore.isDesktop ? 'small-text' : 'small-text-mobile'" style="color: #6e7580"
						v-if="!loading">
						{{
		cardCountMap.get(deck.data.id) ? cardCountMap.get(deck.data.id).total ?
			cardCountMap.get(deck.data.id).total : 0 : 0
	}}
					</span>
				</p>
			</td>
			<td v-if="windowSizeStore.isDesktop" class="data-cell">
				{{
		cardCountMap.get(deck.data.id) ? cardCountMap.get(deck.data.id).new_cards ?
			cardCountMap.get(deck.data.id).new_cards : 0 : 0
	}}
			</td>
			<td v-if="windowSizeStore.isDesktop" class="data-cell">
				{{
		cardCountMap.get(deck.data.id) ? cardCountMap.get(deck.data.id).learning_cards ?
			cardCountMap.get(deck.data.id).learning_cards : 0 : 0
	}}
			</td>
			<td class="data-cell" :class="windowSizeStore.isDesktop ? 'last-cell' : 'last-cell-mobile'">
				{{
		cardCountMap.get(deck.data.id) ? cardCountMap.get(deck.data.id).review_cards ?
			cardCountMap.get(deck.data.id).review_cards : 0 : 0
	}}
			</td>
		</tr>
		<DeckPreviewTableRow :decks="deck.children" v-if="deck.children && displayChildren[deck.data.id]"
			:depth="depth + 1" />
	</template>
</template>

<style scoped>
.big-text {
	font-size: 16px;
	font-weight: 500;
	color: #4a5361;
}

.small-text {
	font-size: 14px;
	font-weight: 500;
	color: #9a9ea6;
}

.big-text-mobile {
	font-size: 14px;
	font-weight: 500;
	color: #4a5361;
}

.small-text-mobile {
	font-size: 12px;
	font-weight: 500;
	color: #9a9ea6;
}

.table-row {
	overflow: auto;
	cursor: pointer;
	transition: background-color 0.3s ease-in-out;
}

.table-row:hover {
	background-color: rgb(250, 250, 250);
}

.last-cell {
	border-right: none !important;
}

.last-cell-mobile {
	font-size: 14px !important;
}

.data-cell {
	text-align: center;
	font-size: 16px;
	width: 5%;
	border-left: 1px solid rgb(241, 232, 244);
	border-right: 1px solid rgb(241, 232, 244);
	color: #4a5361;
}

.table-data-row {
	display: flex;
	align-items: center;
	padding-left: 24px;
	width: 85%;
}

.table-data-row-mobile {
	display: flex;
	align-items: center;
	padding-left: 16px;
	width: 95%;
}

li {
	list-style: none;
}

.link {
	text-decoration: none;
	border-radius: 10px;
}

.plus {
	filter: invert(33%) sepia(13%) saturate(567%) hue-rotate(178deg) brightness(92%) contrast(93%);
	height: 24px;
	width: 24px;
}

.plus-mobile {
	filter: invert(33%) sepia(13%) saturate(567%) hue-rotate(178deg) brightness(92%) contrast(93%);
	height: 20px;
	width: 20px;
}

.link:hover {
	background-color: #fafafa;
}

.hover {
	background-color: #fafafa;
}
</style>