import posthog from 'posthog-js'

export default {
	install(app) {
		app.config.globalProperties.$posthog = posthog.init(import.meta.env.VITE_POSTHOGKEY, {
			api_host: 'https://studyflash-avffcrddhheqgmd0.z01.azurefd.net',
			ui_host: 'https://eu.posthog.com',
			capture_pageview: false,
			person_profiles: 'identified_only', //TODO: check alawys or for identified users only
		})
	},
}
