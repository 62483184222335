<script>
import { useWindowSizeStore } from '../../store/store.js'

export default {
    name: "MaintenanceComponent",
    setup() {
        const windowSizeStore = useWindowSizeStore()

        return {
            windowSizeStore
        }
    },
    data() {
        return {
            dotNumber: 0,
            interval: null
        }
    },
    mounted() {
        this.interval = setInterval(() => {
            this.updateDotNumber()
        }, 500)
    },
    unmounted() {
        clearInterval(this.interval)
    },
    methods: {
        updateDotNumber() {
            this.dotNumber = (this.dotNumber % 3) + 1;
        }
    },
    computed: {
        dots() {
            return '.'.repeat(this.dotNumber).padEnd(3, ' ');
        }
    }
}
</script>

<template>
    <div class="loader-background">
        <div class="content">
            <img src="../../assets/logo-white.svg" alt="SF-logo" class="sf-logo"
                :width="windowSizeStore.isDesktop ? 500 : 300" :height="windowSizeStore.isDesktop ? 120 : 71">
            <p class="message">
                We're currently running updates
            </p>
            <p class="message">
                The application will be available again in 30 minutes
            </p>
            <p class="dots">
                {{ dots }}
            </p>
        </div>
    </div>
</template>

<style scoped>
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;600&display=swap');

.loader-background {
    width: 100%;
    height: 100vh;
    background-color: #7a219e;
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: 'Inter', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';
}

.content {
    text-align: center;
}

@keyframes fadeIn {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

.sf-logo {
    animation: fadeIn 1s ease-out;
    margin-bottom: 2rem;
}

@keyframes fadeInSlideDown {
    0% {
        opacity: 0;
        transform: translateY(-15px);
    }

    25% {
        opacity: 1;
        transform: translateY(-15px);
    }

    100% {
        opacity: 1;
        transform: translateY(0px);
    }
}

.message {
    animation: fadeInSlideDown 1s ease-in;
    font-size: clamp(1.2rem, 4vw, 2rem);
    color: white;
    margin: 0.5rem 0;
    font-weight: 400;
    line-height: 1.4;
}

.dots {
    font-size: clamp(1.2rem, 4vw, 2rem);
    color: white;
    height: 2rem;
    margin-top: 1rem;
    font-weight: 600;
}

@media (max-width: 768px) {
    .content {
        padding: 0 1rem;
    }

    .message {
        margin: 1rem 0;
    }
}
</style>