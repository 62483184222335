import { defineStore } from 'pinia'
import Tree from '../data_structures/DeckTree.js'
import { supabase } from '../common/database'
import { DeckService } from '../services/deck.service.js'
import { CustomStudySessionService } from '../services/customStudySession.service.js'
import { FlashcardService } from '../services/flashcard.service.js'
import { SubscriptionService } from '../services/subscription.service.js'
import { TransactionService } from '../services/transaction.service.js'
import { checkIfRatingExists } from '../services/promoter.score.service.js'
import { captureException } from '@sentry/vue'
import { v4 as uuidv4 } from 'uuid'
import { UserService } from '../services/user.service.js'
import { SessionService } from '../services/session.service.js'

//TODO: split the store into multiple files

export async function initStores() {
	const windowSizeStore = useWindowSizeStore()
	const userStore = useUserStore()
	const transactionStore = useTransactionStore()
	const subscriptionStore = useSubscriptionStore()
	const deckStore = useDeckStore()
	const statisticsStore = useStatisticsStore()

	const initFunctions = [
		subscriptionStore.init(),
		userStore.init(),
		transactionStore.init(),
		deckStore.init(),
	]

	windowSizeStore.updateIsDesktop()
	const resultArray = await Promise.all(initFunctions)
	if (!resultArray.every(value => value === true)) {
		captureException('Store initialization failed, possible infinite loading issue!')
		return
	}
	await statisticsStore.fetchStatisticsData()
}

export const useTransactionStore = defineStore('transactions', {
	state: () => ({
		transactions: [],
		transactionSubscription: null,
		tokenBalance: 0,
	}),
	getters: {},
	actions: {
		async init() {
			const transactionResponse = await TransactionService.fetchInitialData()
			if (transactionResponse.error) {
				console.error(transactionResponse.error)
				captureException(transactionResponse.error)
				return false
			}
			if (transactionResponse.data.length === 0) return false
			this.transactions.push(...transactionResponse.data)
			this.computeTokenBalance()
			this.transactionSubscription = TransactionService.subscribeToInserts(
				this.handleNewTransaction
			)
			return true
		},
		computeTokenBalance() {
			this.transactions.forEach((t) => {
				if (!['subscription', 'trial_deck'].includes(t.payment_type)) {
					this.tokenBalance += t.tokens
				}
			})
		},
		handleNewTransaction(payload) {
			this.transactions.push(payload.new)
			if (!['subscription', 'trial_deck'].includes(payload.new.payment_type)) {
				this.tokenBalance += payload.new.tokens
			}
		},
	},
})

// You can name the return value of `defineStore()` anything you want,
// but it's best to use the name of the store and surround it with `use`
// and `Store` (e.g. `useUserStore`, `useCartStore`, `useProductStore`)
// the first argument is a unique id of the store across your application
export const useDeckStore = defineStore('decks', {
	state: () => ({
		decks: null,
		newDeckTree: new Tree({ id: 0, name: 'RootNode' }),

		deckSubscription: null,
		deckUpdateSubscription: null,
		deckInsertSubscription: null,

		firstFetch: true,
		draggedDeckId: null,

		feedbackDeckId: null,
	}),
	getters: {},
	actions: {
		async init() {
			const response = await DeckService.initialFetch()
			if (response.error) {
				console.error(response.error)
				captureException(response.error)
				return false
			}
			this.decks = [...response.data]
			this.newDeckTree.insertArray([...response.data])
			this.deckUpdateSubscription = DeckService.subscribeToUpdate(this.handleDeckUpdate)
			this.deckInsertSubscription = DeckService.subscribeToInsert(this.handleDeckInsert)
			return true
		},
		handleDeckUpdate(payload) {
			if (payload.new._deleted) {
				this.handleDeckDelete(payload.new.id)
				return
			}
			if (this.newDeckTree.findBFS(payload.new.id) === null) {
				this.handleDeckInsert(payload)
				return
			}
			this.newDeckTree.updateNode(payload.new)
			this.decks = this.decks.map((deck) =>
				deck.id === payload.new.id ? { ...deck, ...payload.new } : deck
			)
		},
		handleDeckInsert(payload) {
			this.decks.push(payload.new)
			this.newDeckTree.add(payload.new, payload.new.parent_deck_id)
		},
		handleDeckDelete(id) {
			this.decks = this.decks.filter((d) => d.id !== id)
			this.newDeckTree.removeNode(id)
		},
		createDeck(deck) {
			return DeckService.create(deck)
		},
		getAncestors(targetId) {
			const ancestors = []
			const allDecks = this.decks

			function findAncestorsRecursive(deckId) {
				const object = allDecks.find((obj) => obj.id == deckId)

				if (object) {
					ancestors.push(object.name)
					if (object.parent_deck_id !== null) {
						findAncestorsRecursive(object.parent_deck_id)
					}
				}
			}
			findAncestorsRecursive(targetId)
			return ancestors
		},
		setFeedbackDeckId(id) {
			this.feedbackDeckId = id
		},
	},
})

export const useWindowSizeStore = defineStore('windowSize', {
	state: () => ({
		isDesktop:
			window.innerWidth > 720 &&
			!/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent),
		sidebarCollapsed: false,
		globalLoading: true,
		displaySettings: false,
		displayReferral: false,
		displayFeedback: false,
		newDeck: false,
	}),
	actions: {
		updateIsDesktop() {
			this.isDesktop =
				window.innerWidth > 720 &&
				!/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)
		},
		toggleSidebar() {
			this.sidebarCollapsed = !this.sidebarCollapsed
		},
		setGlobalLoading(value) {
			this.globalLoading = value
		},
		toggleSettings() {
			this.displaySettings = !this.displaySettings
		},
		toggleReferral() {
			this.displayReferral = !this.displayReferral
		},
		toggleFeedback() {
			this.displayFeedback = !this.displayFeedback
		},
		setNewDeck(value) {
			this.newDeck = value
		},
	},
})

export const useUserStore = defineStore('user', {
	state: () => ({
		user: null,
		userSubscription: null,
		userSession: null,
		askForPromoterScoreRating: false,
		accountDeletedDate: null,
	}),
	getters: {},
	actions: {
		async init() {
			const userResponse = await UserService.fetchUser()
			if (userResponse.error) {
				console.error(userResponse.error)
				captureException(userResponse.error)
				return false
			}
			if (userResponse.data.length !== 1) {
				captureException("User's account not found")
				return false
			}
			this.user = userResponse.data[0]
			this.userSubscription = UserService.subscribeToChanges(this.handleProfileChange)
			return true
		},
		handleProfileChange(payload) {
			this.user = payload.new
		},
		setUserSession(session) {
			this.userSession = session
			this.user_id = session.user.id
		},
		async changeLocale(newValue, localeObject) {
			const userStore = useUserStore()
			const response = await UserService.updateUserLocale(newValue, userStore.user.id)
			if (!response || response.error) {
				return response
			}
			localStorage.setItem('locale', newValue)
			localeObject.value = newValue
			return response
		},
		getCurrentUserId() {
			if (this.user !== null) return this.user.id
			const tokenName = import.meta.env.VITE_AUTH_TOKEN_NAME
			const token = localStorage.getItem(tokenName)
			const tokenObject = JSON.parse(token)
			if (!tokenObject) return null
			return tokenObject.user.id
		},
		async getNumberOfTokens() {
			try {
				const { data, error } = await supabase.rpc('calculate_user_tokens')
				if (error) {
					console.error(error)
					captureException(error)
					return null
				} else {
					return data
				}
			} catch (err) {
				console.error('An unexpected error occurred:', err)
				captureException(err)
				return null
			}
		},
		async checkIfAccountDeleted(email) {
			const response = await supabase.from('deleted_profiles').select('*').eq('user_email', email)
			if (response.error) {
				console.error(response.error)
				captureException(response.error)
				return null
			}
			if (response.data.length === 0) return false
			this.setAccountDeletedDate(response.data[0].deleted_at)
			this.signOut()
			return true
		},
		async toggleAskForPromoterScoreRating() {
			if (this.askForPromoterScoreRating) {
				this.askForPromoterScoreRating = false
				return
			}
			if (useSubscriptionStore().getSubscriptionStatus() === 0) return
			const userRatingExists = await checkIfRatingExists()
			if (userRatingExists) return
			this.askForPromoterScoreRating = true
		},
		async setNewAvatar(newAvatar, userId, authId) {
			const uploadResponse = await UserService.uploadNewAvatar(authId, newAvatar)
			if (uploadResponse.error) return uploadResponse
			const publicUrl = UserService.getAvatarPublicUrl(authId)
			const updateResponse = await UserService.setNewAvatar(publicUrl.data.publicUrl, userId)
			return updateResponse
		},
		async hideWelcomeMessage() {
			this.user.seen_welcome_message = true
			return await UserService.setSeenWelcomeMessage(this.getCurrentUserId())
		},
		async signOut(router) {
			const { error } = await UserService.signOut()
			if (error) {
				console.error(error)
				captureException(error)
				return
			}
			this.user = null
			this.userSubscription = null
			this.userSession = null
			if (!router) return
			router.push('/signup')
		},
		setAccountDeletedDate(date) {
			this.accountDeletedDate = date
		},
		async setNpsFlagFalse() {
			const userId = this.getCurrentUserId()
			if (!userId) return null
			const response = await UserService.setNpsFlagFalse(userId)
			if (response.error) return response
			this.user.show_nps_popup = false
			this.toggleAskForPromoterScoreRating()
			return response
		},
		async setOnboardingCompleted() {
			const userId = this.getCurrentUserId()
			if (!userId) return null
			await UserService.setOnboardingCompleted(userId)
			this.user.show_onboarding_tour = false
		},
	},
})

export const useCustomStudySessionStore = defineStore('customStudySession', {
	state: () => ({
		studySessions: [],
	}),
	getters: {},
	actions: {
		async createNewCustomStudySession(sessionDTO, deckId) {
			const sessionResponse = await CustomStudySessionService.createStudySession(sessionDTO)
			if (sessionResponse.error) {
				console.error(sessionResponse.error)
				captureException(sessionResponse.error)
				return false
			}
			const session = sessionResponse.data[0]

			const allCards = await this.getAllFlashcardsForCustomStudySession(deckId)

			let bulkInsertArray = []
			allCards.forEach((card) => {
				bulkInsertArray.push({
					id: uuidv4(),
					study_session_id: session.id,
					card_id: card.id,
					show: true,
					created_by: sessionDTO.created_by,
				})
			})
			const bulkInsertResponse = await CustomStudySessionService.bulkCreateReferences(
				bulkInsertArray
			)
			if (bulkInsertResponse.error) {
				console.error(bulkInsertResponse.error)
				captureException(bulkInsertResponse.error)

				return false
			}
			this.studySessions.push(session)
			return session.id
		},
		async getAllFlashcardsForCustomStudySession(deckId) {
			const deckStore = useDeckStore()
			const subscriptionStore = useSubscriptionStore()
			const allChildDeckIds = deckStore.newDeckTree.getAllChildrenIds(deckId)
			const allCardsResults = await Promise.all(
				allChildDeckIds.map((childDeckId) => FlashcardService.getDecksFlashcards(childDeckId))
			)
			let allCards = allCardsResults.flat()

			// TODO: REMOVE
			if (subscriptionStore.getSubscriptionStatus() !== 0) return allCards

			allCards = allCards.sort((a, b) => {
				if (a.chunk_order_id === null && b.chunk_order_id !== null) return -1
				if (a.chunk_order_id !== null && b.chunk_order_id === null) return 1
				if (a.chunk_order_id !== null && b.chunk_order_id !== null) {
					if (a.chunk_order_id < b.chunk_order_id) return -1
					if (a.chunk_order_id > b.chunk_order_id) return 1
				}

				if (a.order_id < b.order_id) return -1
				if (a.order_id > b.order_id) return 1

				if (a.position < b.position) return -1
				if (a.position > b.position) return 1

				return 0
			})
			allCards = allCards.slice(0, 49)

			return allCards
		},
		async deleteSession(sessionId) {
			const deletionResponse = await CustomStudySessionService.deleteSession(sessionId)
			if (deletionResponse.error) {
				return deletionResponse
			}
			this.studySessions = this.studySessions.filter((session) => session.id !== sessionId)
			return deletionResponse
		},
	},
})

export const useSubscriptionStore = defineStore('subscription', {
	state: () => ({
		subscription: null,
		subscriptionSubscription: null,
	}),
	getters: {},
	actions: {
		async init() {
			const subscriptionResponse = await SubscriptionService.fetchSubscription()
			if (subscriptionResponse.error) {
				console.error(subscriptionResponse.error)
				captureException(subscriptionResponse.error)
				return false
			}
			if (subscriptionResponse.data.length === 0) this.subscription = null
			else {
				this.subscription = subscriptionResponse.data[0]
				this.subscriptionSubscription = SubscriptionService.subscribeToChanges(
					(payload) => (this.subscription = payload.new)
				)
			}
			return true
		},
		// 0 - new user, no subscription ever
		// 1 - new user, subscription inactive
		// 2 - new user, subscription active
		// 3 - old user, no subscription ever
		// 4 - old user, subscription inactive
		// 5 - old user, subscription active
		getSubscriptionStatus() {
			const userStore = useUserStore()
			if (!userStore.user) return 6
			if (userStore.user.subscription_user) {
				if (this.subscription === null) return 0
				return new Date(this.subscription.end_date).getTime() < new Date().getTime() ? 1 : 2
			} else {
				if (this.subscription === null) return 3
				return new Date(this.subscription.end_date).getTime() < new Date().getTime() ? 4 : 5
			}
		},
	},
})

export const useStatisticsStore = defineStore('statistics', {
	state: () => ({
		statisticsData: null,
	}),
	getters: {},
	actions: {
		async fetchStatisticsData() {
			const userStore = useUserStore()
			const userId = userStore.getCurrentUserId()
			if (!userId) return
			this.statisticsData = await SessionService.getStatistics(userId)
		},
	},
})
