<script>
import { useWindowSizeStore } from '../../store/store';

export default {
    name: "NavbarInviteSection",
    setup() {
        const windowSizeStore = useWindowSizeStore()
        return {
            windowSizeStore
        }
    },
    methods: {
        openReferral() {
            this.windowSizeStore.toggleReferral()
        }
    }
}
</script>

<template>
    <div class="invite-friend-card">
        <img src="../../assets/megaphoneImage.svg" alt="Image" class="invite-friend-image" />
        <h1 class="standard_text" style="font-size: 16px; margin: 0; color: #6e7580; font-weight: 500">
            {{ $t('message.leftNav.inviteFriendText1') }}
        </h1>
        <h1 class="standard_text" style="font-size: 20px">
            {{ $t('message.leftNav.inviteFriendText2') }}
        </h1>
        <button href="#" class="primary" style="padding: 25px; margin-bottom: 12px; width: max-content"
            @click="openReferral">
            {{ $t('message.leftNav.inviteFriendButton') }}
        </button>
    </div>
</template>

<style scoped>
.invite-friend-image {
    width: 70%;
    margin-bottom: 24px;
}

@media (max-height: 800px) {
    .invite-friend-image {
        width: 50%;
        margin-bottom: 20px;
    }
}

@media (max-height: 750px) {
    .invite-friend-image {
        width: 45%;
        margin-bottom: 16px;
    }
}

@media (max-height: 700px) {
    .invite-friend-image {
        width: 40%;
        margin-bottom: 12px;
    }
}

@media (max-height: 660px) {
    .invite-friend-image {
        display: none;
        margin-bottom: 8px;
    }
}

.invite-friend-card {
    margin-bottom: 0.5rem;
    width: 100%;
    padding: 16px;
    border-radius: 8px;
    background: linear-gradient(160deg,
            rgb(241, 233, 245) 0%,
            rgb(241, 233, 245) 50%,
            rgb(214, 164, 241) 100%);
}
</style>