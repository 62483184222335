<script>
import { useI18n } from 'vue-i18n'

export default {
	name: 'StreakComponent',
	setup() {
		const { locale } = useI18n({ useScope: 'global' })
		return {
			locale
		}
	},
	props: {
		days: {
			type: Array,
			required: true,
		},
		streak: {
			type: Number,
		},
	},
	data() {
		return {
			daysMap: new Map([
				['Mon', 'Mo'],
				['Tue', 'Di'],
				['Wed', 'Mi'],
				['Thu', 'Do'],
				['Fri', 'Fr'],
				['Sat', 'Sa'],
				['Sun', 'So']
			])
		}
	},
	methods: {
		displayDayName(day) {
			if (this.locale == 'en') return day
			return this.daysMap.get(day)
		}
	},
	computed: {
		dayOfWeek() {
			let day = new Date().getDay()
			if (day === 0) return 6
			return day - 1
		},
		hasStudiedToday() {
			let dayToCheck = this.dayOfWeek
			if (this.days[dayToCheck].studied) return true
			return false
		},
		currentLocale() {
			return this.locale
		}
	},
}
</script>


<template>
	<div class="streak-container border" id="streakComponent">
		<div class="text-container">
			<h2 class="streak-text">
				{{ $t('message.dashboard.studyingFor1') }}
				<span style="color: #7a219e">{{
			streak == 1
				? streak + ' ' + $t('message.dashboard.day')
				: streak + ' ' + $t('message.dashboard.days')
		}}
				</span>
				{{ $t('message.dashboard.studyingFor2') }}
			</h2>
			<p class="streak-subtext">
				{{ $t('message.dashboard.study') }}
				{{ hasStudiedToday ? $t('message.dashboard.tomorrow') : $t('message.dashboard.today') }}
				{{ $t('message.dashboard.toContinue') }}
			</p>
			<div class="days-container" v-if="days.length !== 0">
				<div v-for="(day, index) in days" :key="index" class="day-card"
					:class="day.studied ? 'studied' : index === dayOfWeek ? 'today-not-yet' : 'not'">
					<div class="day-name">
						{{ displayDayName(day.day) }}
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<style scoped>
.day-icon {
	margin-top: 3px;
	font-size: 1.2rem;
}

.streak-text {
	color: #4a5361;
}

.streak-subtext {
	color: #9a9ea6;
}

.today-not-yet {
	border: 1px solid #7a219e;
	background-color: white !important;
	color: #7a219e;
}

.studied {
	background-color: #7a219e !important;
	color: white;
	border: 1px solid #f1e8f4 !important;
}

.not {
	background-color: white !important;
	border: 1px solid #9a9ea6;
	color: #4a5361;
}

.day-name {
	display: flex;
	justify-content: center;
	font-weight: 700;
	font-size: 1.1rem;
}

.box {
	display: flex;
	justify-content: center;
}

.day-card {
	display: flex;
	flex-direction: column;
	border-radius: 8px;
	background-color: rgba(240, 240, 240, 0.5);
	backdrop-filter: blur(5px);
	width: 4rem;
	padding: 1rem 0;
}

.days-container {
	display: flex;
	margin-top: 2rem;
	gap: 0.5rem;
}

h2,
p {
	padding: 0;
	margin: 0;
	/* backdrop-filter: blur(25px); */
}

.text-container {
	position: absolute;
	top: 18%;
	left: 0%;
	width: 100%;
	height: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;
}

.streak-container {
	min-height: 250px;
	border-radius: 8px;
	position: relative;
	flex: 1;
	display: flex;
	justify-content: space-between;
	padding: 0;
	background-color: #ebdaf4;
	min-width: 500px;
}

</style>