import PriorityQueue from '../../data_structures/FlashcardPriorityQueue.js'
import { supabase } from '../database.js'
import { getNewCardState } from './card_handle_helper.js'
import { captureException } from '@sentry/vue'

class CardCounter {
	constructor() {
		this.new = 0
		this.learning = 0
		this.review = 0
	}

	setCardCounts(newCount, learningCount, reviewCount) {
		this.new = newCount
		this.learning = learningCount
		this.review = reviewCount
	}

	resetCount() {
		this.new = 0
		this.learning = 0
		this.review = 0
	}

	incrementCount(fieldName) {
		switch (fieldName) {
			case 'new':
				this.new++
				break
			case 'learning':
				this.learning++
				break
			case 'lapsed':
				this.learning++
				break
			case 'review':
				this.review++
				break
			default:
				break
		}
	}

	decrementCount(fieldName) {
		switch (fieldName) {
			case 'new':
				this.new--
				break
			case 'learning':
				this.learning--
				break
			case 'lapsed':
				this.learning--
				break
			case 'review':
				this.review--
				break
			default:
				break
		}
	}
}

export default class LearningService {
	constructor() {
		this.priorityQueue = new PriorityQueue()
		this.queueCounter = new CardCounter()
		this.prevCounter = new CardCounter()
		this.prev = null
		this.current = null
		this.deleted = null
		this.currentSession = null
		this.returnedToQueue = false
	}

	async init(deckId, deckIdArray, isFreeUser) {
		await this.fetchCards(deckIdArray, isFreeUser)
		if (!isFreeUser && deckId) {
			await this.countCards(deckId)
			return
		}
		this.countCardsFreeUser()
	}

	async fetchCards(deckIdArray, isFreeUser) {
		let flashcardQuery = supabase
			.from('flashcards')
			.select('*')
			.eq('_deleted', false)
			.lt('next_review', new Date(Date.now() + 15 * 60 * 1000).toISOString())

		if (deckIdArray.length > 0) {
			flashcardQuery = flashcardQuery.in('deck_id', deckIdArray)
		}

		if (isFreeUser) {
			flashcardQuery = flashcardQuery.order('created_at', { ascending: true }).range(0, 49)
		}

		const flashcardResponse = await flashcardQuery

		if (flashcardResponse.error) {
			console.error('Error fetching cards:', flashcardResponse.error)
			captureException(flashcardResponse.error)
			return false
		}

		this.priorityQueue.push(...flashcardResponse.data)
		this.current = this.priorityQueue.pop()
		return true
	}

	async countCards(deckId) {
		const cardCountResponse = await supabase.rpc('get_flashcard_counts', {
			initial_id: deckId,
		})
		if (cardCountResponse.error) {
			console.error('Error counting cards:', cardCountResponse.error)
			captureException(cardCountResponse.error)
			return false
		}
		const countData = cardCountResponse.data[0]
		this.queueCounter.setCardCounts(
			countData.new_cards,
			countData.learning_cards,
			countData.review_cards
		)
		return true
	}

	async countCardsFreeUser() {
		if (this.priorityQueue._heap.length === 0) return
		const countData = {
			new_cards: 0,
			learning_cards: 0,
			review_cards: 0,
		}
		this.priorityQueue._heap.forEach((flashcard) => {
			switch (flashcard.type) {
				case 'new':
					countData.new_cards++
					break
				case 'learning':
					countData.learning_cards++
					break
				case 'lapsed':
					countData.learning_cards++
					break
				case 'review':
					countData.review_cards++
					break
				default:
					break
			}
		})
		this.queueCounter.setCardCounts(
			countData.new_cards,
			countData.learning_cards,
			countData.review_cards
		)
		this.queueCounter.incrementCount(this.current.type)
	}

	async rateCard(difficulty) {
		this.prev = { ...this.current }
		const newCardState = await this.updateCardState(difficulty)
		this.returnedToQueue = false
		if (this.shouldReturnToQueue(newCardState)) {
			this.priorityQueue.push(newCardState)
			this.returnedToQueue = true
		}
		this.updateCounters(newCardState)
		this.current = this.priorityQueue.pop()
	}

	async updateCardState(difficulty) {
		const currentCardState = { ...this.current }
		const newCardState = getNewCardState(currentCardState, difficulty)
		const updateResponse = await supabase
			.from('flashcards')
			.update(newCardState)
			.eq('id', this.current.id)
		if (updateResponse.error) {
			console.error('Error updating card state:', updateResponse.error)
			captureException(updateResponse.error)
			return
		}
		return newCardState
	}

	updateCounters(newCardState) {
		if (!this.returnedToQueue) {
			this.queueCounter.decrementCount(this.prev.type)
			return
		}
		if (this.prev.type === newCardState.type) {
			return
		}
		this.prevCounter.resetCount()
		this.prevCounter.decrementCount(newCardState.type)
		this.prevCounter.incrementCount(this.prev.type)
		this.queueCounter.decrementCount(this.prev.type)
		this.queueCounter.incrementCount(newCardState.type)
	}

	shouldReturnToQueue(newCardState) {
		const now = new Date()
		const threeHoursFromNow = new Date(now.getTime() + 3 * 60 * 60000)
		const shouldReturn = new Date(newCardState.next_review).getTime() < threeHoursFromNow.getTime()
		return shouldReturn
	}

	async deleteCard() {
		this.deleted = { ...this.current }
		const deletionResponse = await supabase
			.from('flashcards')
			.update({ _deleted: true })
			.eq('id', this.current.id)
		if (deletionResponse.error) {
			console.error('Error deleting card:', deletionResponse.error)
			captureException(deletionResponse.error)
			return
		}
		this.queueCounter.decrementCount(this.deleted.type)
		this.prevCounter.resetCount()
		this.prev = null
		this.current = this.priorityQueue.pop()
	}

	async revertLastRating() {
		if (this.returnedToQueue) {
			this.priorityQueue.remove(this.prev.id)
		}
		const revertResponse = await supabase
			.from('flashcards')
			.update(this.prev)
			.eq('id', this.prev.id)
		if (revertResponse.error) {
			console.error('Error reverting last rating:', revertResponse.error)
			captureException(revertResponse.error)
			return
		}
		this.updateCountersOnPrevious()
		if (this.current.id !== this.prev.id) {
			this.priorityQueue.push(this.current)
		}
		this.current = { ...this.prev }
		this.prev = null
	}

	updateCountersOnPrevious() {
		if (this.returnedToQueue) {
			this.queueCounter.new += this.prevCounter.new
			this.queueCounter.learning += this.prevCounter.learning
			this.queueCounter.review += this.prevCounter.review
			this.prevCounter.resetCount()
			return
		}
		this.queueCounter.new += this.prevCounter.new < 0 ? 0 : this.prevCounter.new
		this.queueCounter.learning += this.prevCounter.learning < 0 ? 0 : this.prevCounter.learning
		this.queueCounter.review += this.prevCounter.review < 0 ? 0 : this.prevCounter.review
		this.prevCounter.resetCount()
	}

	async restoreCard() {
		const restoreResponse = await supabase
			.from('flashcards')
			.update({ _deleted: false })
			.eq('id', this.deleted.id)
		if (restoreResponse.error) {
			console.error('Error restoring card:', restoreResponse.error)
			captureException(restoreResponse.error)
			return
		}
		if (this.current) {
			this.priorityQueue.push(this.current)
		}
		this.queueCounter.incrementCount(this.deleted.type)
		this.current = this.deleted
		this.deleted = null
		this.prev = null
		this.prevCounter.resetCount()
	}

	async updateQuestionAndAnswer(question, answer) {
		const updateResponse = await supabase
			.from('flashcards')
			.update({ question: question, answer: answer })
			.eq('id', this.current.id)
		if (updateResponse.error) {
			console.error('Error updating question and answer:', updateResponse.error)
			captureException(updateResponse.error)
			return
		}
		this.current.question = question
		this.current.answer = answer
	}
}
