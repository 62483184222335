<template>
    <div v-on:click="closeFeedback" class="popup-background" id="feedback">
        <div class="popup " v-on:click.stop
            :class="windowSizeStore.isDesktop ? 'feedback-popup' : 'feedback-popup-mobile'">
            <div class="container" style="justify-content: space-between; align-items: center;  margin-bottom: 24px;">
                <h1 class="standard_text" style="font-size: 24px; margin-bottom: 0;">
                    {{ $t('message.feedback.submitFeedback') }}</h1>
                <fa-icon icon="fa-solid fa-x" class="icon" @click="closeFeedback"></fa-icon>
            </div>
            <div>
                <form>
                    <div class="form-group">
                        <label for="title" class="input-label">{{ $t('message.feedback.title') }}</label>
                        <input v-model="title" type="text" id="title" name="title" required>
                    </div>
                    <div class="form-group" style="margin-bottom: 0;">
                        <label for="description" class="input-label">{{ $t('message.feedback.description') }}</label>
                        <textarea v-model="description" type="text" id="description" name="description"
                            aria-multiline="true" @paste="hijackPaste"></textarea>
                    </div>
                    <div class="container" style="align-items: center;">
                        <img src="../../assets/paper-clip.svg">
                        <div class="form-group" style="justify-content: center;">
                            <input type="file" id="image" name="image" style="display: none" accept="image/*"
                                @change="handleImageUpload" multiple>
                            <label for="image"
                                style="cursor: pointer; font-size: 14px; font-weight: 600; padding-top: 15px;"
                                class="card_text">{{ $t('message.feedback.attachImages') }}</label>
                        </div>
                    </div>
                    <div class="uploaded-images-container">
                        <div v-for="(image, index) in uploadedImages" :key="index" class="uploaded-image-component">
                            {{ image.name }}
                            <fa-icon icon="fa-sold fa-x" class="icon" @click="handleRemove(index)"></fa-icon>
                        </div>
                    </div>
                    <div class="form-group" style="text-align: right; margin-bottom: 0;">
                        <button @click="closeFeedback" class="secondary"
                            style="display: inline-block; margin-right: 10px; border: #f1e8f4 1px solid; margin-bottom: 0;">{{
                                $t('message.feedback.dismiss') }}</button>
                        <button @click.prevent="handleSubmit" class="primary" :disabled="!title || !description">{{
                            $t('message.feedback.submit')
                            }}</button>
                    </div>
                </form>
            </div>
        </div>
    </div>
</template>

<script setup>
import { ref, onMounted } from 'vue'
import { useUserStore, useWindowSizeStore } from '@/store/store.js'
import { v4 as uuidv4 } from 'uuid';
import { supabase } from '@/common/database.js'
import { replaceSpacesWithUnderscores, uploadPublicFileAndGetItsLink } from '@/common/util.js'
import { toast } from 'vue3-toastify';
import { useI18n } from 'vue-i18n'
import { FeedbackService } from '../../services/feedback.service'
import { captureException } from "@sentry/vue";


const { t } = useI18n()

const userStore = useUserStore()
const windowSizeStore = useWindowSizeStore()

const description = ref('')
const title = ref('')
const uploadedImages = ref([])

function closeFeedback() {
    windowSizeStore.toggleFeedback()
}

function handleImageUpload(event) {
    let imageArray = event.target.files
    uploadedImages.value = [...uploadedImages.value, ...imageArray]
}

//TODO: move all logic to services and activate them via store
async function hijackPaste($event) {
    $event.stopPropagation();

    let clipboardData = $event.clipboardData || window.clipboardData;
    if (clipboardData.files.length === 0) return

    let image = clipboardData.files[0]
    let newName = "screenshot_" + image.lastModified
    let newFile = new File([image], newName, { type: 'image/png' })
    uploadedImages.value.push(newFile)
}

function handleRemove(index) {
    uploadedImages.value.splice(index, 1)
}

async function uploadAndGetPublicUrlOfImages() {
    let objArray = []
    const userId = userStore.getCurrentUserId()
    uploadedImages.value.forEach(image => {
        let imageName = replaceSpacesWithUnderscores(image.name)
        let imagePath = userId + '/' + imageName
        let obj = { image: image, imagePath: imagePath }
        objArray.push(obj)
    });
    const promises = objArray.map(obj => uploadPublicFileAndGetItsLink('FeedbackImages', obj.imagePath, obj.image))
    const urlArray = await Promise.all(promises)
    return urlArray
}

async function handleSubmit() {
    const urlArray = await uploadAndGetPublicUrlOfImages()
    let feedback = {
        id: uuidv4(),
        description: description.value,
        title: title.value,
        created_by: userStore.getCurrentUserId()
    }
    // TODO: Transform into store action
    let res = await FeedbackService.create(feedback)

    let email = userStore.user.email
    let username = userStore.user.email.split('@')[0]
    let date_time = new Date().toLocaleString('de-DE');

    const { _, _error } = await supabase.functions.invoke('send-email-about-feedback', {
        body: {
            title: title.value,
            description: description.value,
            username: username,
            date_time: date_time,
            email: email,
            imagesArray: urlArray
        },
    })

    if (_error) {
        console.error(_error)
        captureException(_error)
        toast(t('message.errorCodes.general.feedbackFailed'))
        return
    }
    toast(t('message.feedback.feedbackSuccess'))
    windowSizeStore.toggleFeedback()
}
</script>

<style scoped>
.icon {
    font-weight: 700;
    cursor: pointer;
    margin-left: 8px;
    height: 14px;
}

.uploaded-image-component {
    border: 1px solid #7a219e;
    border-radius: 8px;
    padding: 4px 8px;
    color: #7a219e;
    font-size: 14px;
}

.uploaded-images-container {
    display: flex;
    flex-wrap: wrap;
    gap: 8px;
    margin-bottom: 16px;
}

.feedback-popup {
    width: 488px;
    padding: 24px 32px;
    min-width: 360px;
    top: 40%;
}

.feedback-popup-mobile {
    width: 90%;
    padding: 24px 32px;
    min-width: 360px;
    top: 40%;
}

.input-label {
    font-size: 14px;
    font-weight: 700;
    color: #9a9ea6;
}

input[type="text"] {
    width: 100%;
    padding: 0.5rem;
    border-radius: 5px;
    border: 1px solid #f1e8f4;
    font-size: 1rem;
    margin-bottom: 0.5rem;
    height: 44px;
    color: #666;
}

textarea {
    width: 100%;
    padding: 0.5rem;
    border-radius: 5px;
    border: 1px solid #f1e8f4;
    font-size: 1rem;
    margin-bottom: 0.5rem;
    resize: none;
    color: #666;
    min-height: 6rem;
}

input[type="file"] {
    margin-top: 0.5rem;
}

#image {
    border: none;

}
</style>
