<template>
    <div v-if="showPopup" class="popup-background" :style="backgroundStyles" @click="closePopup"></div>
    <div v-if="showPopup" :class="isLarge && !windowSizeStore.isDesktop ? 'popup-large' : 'popup'"
        :style="windowSizeStore.isDesktop ? popupStyles.desktop : popupStyles.mobile">
        <div :class="title ? 'popup-header' : 'popup-header-no-title'">
            <span v-if="title" :class="windowSizeStore.isDesktop ? 'popup-title' : 'popup-title-mobile'">{{ title
                }}</span>
            <fa-icon icon="fa-sold fa-x" class="icon color-icon" @click="closePopup"></fa-icon>
        </div>
        <div class="popup-content">
            <slot></slot>
        </div>
        <div class="popup-actions" v-if="!hideActionButtons">
            <button class="cancel-button action-button" @click="cancelAction">{{ cancelText }}</button>
            <button :class="isDeleteConfirm ? 'delete-button action-button' : 'submit-button action-button'"
                v-if="!isInformational" @click="submitAction">{{ submitText
                }}</button>
        </div>
    </div>
</template>

<script>
import { useWindowSizeStore } from '../../store/store';

export default {
    name: 'GenericPopup',
    setup() {
        const windowSizeStore = useWindowSizeStore()
        return {
            windowSizeStore
        }
    },
    props: {
        showPopup: {
            type: Boolean,
            default: false,
        },
        submitAction: {
            type: Function,
            default: () => { },
        },
        cancelAction: {
            type: Function,
            default: () => { },
        },
        submitText: {
            type: String,
            default: 'Submit',
        },
        cancelText: {
            type: String,
            default: 'Cancel',
        },
        isInformational: {
            type: Boolean,
            default: false
        },
        popupStyles: {
            type: Object,
            default: {
                desktop: {},
                mobile: {},
            }
        },
        backgroundStyles: {
            type: Object,
            default: {}
        },
        title: {
            type: String
        },
        isDeleteConfirm: {
            type: Boolean,
            default: false
        },
        isLarge: {
            type: Boolean,
            default: false
        },
        hideActionButtons: {
            type: Boolean,
            default: false
        }
    },
    methods: {
        closePopup(e) {
            e.stopPropagation();
            this.$emit('hide-popup', false);
        },
    },
    emits: ['hide-popup']
};
</script>

<style scoped>
.icon {
    color: #4a5361;
    width: 20px;
    height: 16px;
    cursor: pointer;
}

.popup-title {
    text-align: left;
    font-size: 24px;
    line-height: 1.33;
    margin: 0;
    font-weight: 700;
    color: #4a5361;
}

.popup-title-mobile {
    text-align: left;
    font-size: 20px;
    line-height: 1.33;
    margin: 0;
    font-weight: 700;
    color: #4a5361;
}

.popup {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    flex-direction: column;
    gap: 24px;
    background-color: white;
    padding: 24px 32px 32px;
    z-index: 8;
    width: 40%;
    border-radius: 10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
    max-height: 100dvh;
    overflow-y: auto;
}

.popup-large {
    position: fixed;
    top: 5px;
    left: 50%;
    transform: translate(-50%, 0%);
    display: flex;
    flex-direction: column;
    gap: 24px;
    background-color: white;
    padding: 24px 32px 32px;
    z-index: 8;
    width: 90%;
    border-radius: 10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
    overflow-y: auto;
    max-height: 98vh;
}

.popup-backdrop {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 7;
}

.popup-header-no-title {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin-bottom: 16px;
}

.popup-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.popup-close {
    cursor: pointer;
}

.submit-button {
    background-color: #7A219E;
    color: white;
}

.delete-button {
    background-color: #f04d62;
    color: white;
}

.delete-button:hover {
    background-color: red;
}

.submit-button:hover {
    background-color: #944CB0;
}

.cancel-button {
    color: #7A219E;
    background-color: white;
}

.cancel-button:hover {
    background-color: #F6F1F8;

}

.action-button {
    padding: 11px 24px;
    border-radius: 8px;
    border: 1px solid #F1E8F4;
    font-weight: 700;
}

.popup-actions {
    display: flex;
    justify-content: flex-end;
    text-align: right;
    gap: 1rem;
}

.popup-content {
    text-align: left;
    font-size: 14px;
    line-height: 16px;
    color: #9a9ea6;
}

@media (max-width: 500px) {
    .popup {
        width: 90%;
    }
}
</style>