<script setup>
import { supabase } from '@/common/database'
import { router } from '@/router'
import {
	useWindowSizeStore,
} from '@/store/store.js'
import NavbarNavigation from './NavbarNavigation.vue';
import NavbarInviteSection from './NavbarInviteSection.vue';
import { useUserStore } from '../../store/store';

const windowSizeStore = useWindowSizeStore()
const userStore = useUserStore()

function handleSidebarToggle() {
	windowSizeStore.toggleSidebar()
}

async function handleLogOut() {
	await userStore.signOut(router)
}

function navigateHome() {
	router.push('/dashboard')
}
</script>

<template>
	<div class="toggle-container" id="toggleContainer">
		<div class="navbar-left" :class="{ 'collapsed': windowSizeStore.sidebarCollapsed }" id="NavbarLeft">
			<div style="width: 100%" v-if="!windowSizeStore.sidebarCollapsed">
				<div class="logo" @click="navigateHome">
					<img src="../../assets/logo.svg" alt="Logo" class="logo-image" />
				</div>
				<NavbarNavigation />
			</div>
			<span v-if="!windowSizeStore.sidebarCollapsed">
				<NavbarInviteSection />
				<button class="button" @click="handleLogOut">
					<img src="../../assets/logoutIcon.svg" />
					{{ $t('message.leftNav.logout') }}
				</button>
			</span>
		</div>
		<div class="toggle-sidebar" :class="{ 'toggle-sidebar-active': windowSizeStore.sidebarCollapsed }">
			<button @click="handleSidebarToggle" class="toggle-button">
				<fa-icon v-if="windowSizeStore.sidebarCollapsed" icon="fa-sold fa-angle-right"
					class="icon color-icon"></fa-icon>
				<fa-icon v-else icon="fa-sold fa-angle-left" class="icon color-icon"></fa-icon>
			</button>
		</div>
	</div>
</template>

<style scoped>
.active-overlay {
	opacity: 1;
	background-color: #7a219e;
	pointer-events: auto;
	z-index: 7;
}

.navbar-left {
	transition: width 0.3s ease;
	padding: 20px;
}

.collapsed {
	width: 0;
	overflow: hidden;
	padding: 0;
}

.toggle-button {
	padding: 1rem;
	font-size: 20px;
	background-color: transparent;
	color: #7a219e;
	border-radius: 8px;
	transition: color 0.3s ease, background-color 0.3s ease;
}

.toggle-button:hover {
	background-color: #e6c3f4;
}


.logo {
	cursor: pointer;
	display: flex;
	justify-content: center;
	height: 72px;
}

.logo-image {
	padding: 0;
	height: 38px;
	width: 160px;
}

.toggle-container {
	position: sticky;
	top: 0;
	height: 100vh;
	padding: 0;
	z-index: 7;
}

.toggle-sidebar {
	position: absolute;
	top: 50vh;
	left: 102%;
	z-index: 7;
}

.toggle-sidebar-active {
	left: 110%;
	margin-left: 0.5rem;
}
</style>
