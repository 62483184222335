<template>
	<div @click="openSettings" class="user-info" id="userInfo"
		:style="windowSizeStore.isDesktop ? 'height: 100%;' : ''">
		<div class="container" style="align-items: center">
			<img v-if="avatarUrl" :src="avatarUrl" alt="User Avatar" class="avatar" height="36" width="36"
				loading="lazy" referrerpolicy="no-referrer" />
			<img v-else src="../../assets/avatar.svg" alt="User Avatar" class="avatar" height="36" width="36"
				loading="lazy" />
			<div class="column" style="padding-left: 10px">
				<div>
					<p class="standard_text"
						style="font-size: 16px; margin-bottom: 0; color: #4a5361; font-weight: 500">
						{{ username }}
					</p>
					<p class="standard_text"
						style="font-size: 12px; color: #9a9ea6; margin-bottom: 0; font-weight: 500;">
						{{
							subscriptionStatus
						}}
					</p>
				</div>
			</div>
		</div>
		<img src="../../assets/Settings.svg" style="width: 24px; height: 24px; margin-left: 24px" />
	</div>
</template>

<script>
import { useUserStore, useTransactionStore, useWindowSizeStore, useSubscriptionStore } from '@/store/store.js'

export default {
	setup() {
		const userStore = useUserStore()
		const transactionStore = useTransactionStore()
		const windowSizeStore = useWindowSizeStore()
		const subscriptionStore = useSubscriptionStore()

		return {
			userStore,
			transactionStore,
			windowSizeStore,
			subscriptionStore
		}
	},
	methods: {
		openSettings() {
			this.$emit('open-settings')
		},
	},
	computed: {
		username() {
			if (this.userStore.user && this.userStore.user.name) return this.userStore.user.name
			if (!this.userStore.user || !this.userStore.user.email) return 'Error displaying username'
			return this.userStore.user.email.split('@')[0]
		},
		tokenBalance() {
			return this.transactionStore.tokenBalance
		},
		avatarUrl() {
			if (this.userStore.user?.avatar_url) {
				return this.userStore.user.avatar_url;
			}
			return null;
		},
		subscriptionStatus() {
			let status = this.subscriptionStore.getSubscriptionStatus()
			switch (status) {
				case 0:
					return this.$t('message.header.basicUser')
				case 1:
					return this.$t('message.header.basicUser')
				case 2:
					return this.$t('message.header.premiumUser')
				case 3:
					return (this.tokenBalance >= 0 ? this.tokenBalance : 0) + this.$t('message.header.tokensLeft')
				case 4:
					return (this.tokenBalance >= 0 ? this.tokenBalance : 0) + this.$t('message.header.tokensLeft')
				case 5:
					return this.$t('message.header.premiumUser')
				default:
					return "Unexpected error"
			}
		}
	},
	emits: ['open-settings'],
}
</script>
