<script>
import DomPurify from "dompurify";

export default {
    name: 'CardComponent',
    props: {
        cardInfo: {
            type: Object,
            required: true,
        },
        showAnswer: {
            type: Boolean,
            required: true,
            default: false
        },
        isDesktop: {
            type: Boolean
        }
    },
    methods: {
        handleEmitShowAnswer() {
            this.$emit('show-answer')
        }
    },
    computed: {
        sanitizedHtmlQuestion() {
            if (this.cardInfo) {
                return DomPurify.sanitize(this.cardInfo.question)
            }
            return ''
        },
        sanitizedHtmlAnswer() {
            if (this.cardInfo) {
                return DomPurify.sanitize(this.cardInfo.answer)
            }
            return ''
        },
        calculatedPoints() {
            if (!this.cardInfo) return 0
            const doc = new DOMParser().parseFromString(this.cardInfo.answer, 'text/html')
            const liElements = doc.querySelectorAll('li')
            const count = liElements.length
            return count
        }
    },
    emits: ['show-answer'],
}
</script>

<template>
    <div class="card-wrapper" :class="isDesktop ? 'wrapper-desktop' : ''" id="cardComponent">
        <p class="question-p">
        <div class="wysiwyg-editor">
            <div v-html="sanitizedHtmlQuestion"></div>
        </div>
        </p>
        <hr v-if="showAnswer || calculatedPoints !== 0" class="card-divider">
        <div v-if="!isDesktop && !showAnswer">
            <button @click="handleEmitShowAnswer" class="mobile-show-answer-button">- &nbsp;{{
                $t('message.studyPage.showAnswer') }}&nbsp; -</button>
        </div>
        <div>
            <p v-if="calculatedPoints !== 0 && !showAnswer" class="answer-p">
                ({{ calculatedPoints }} points)
            </p>
            <p v-if="showAnswer" class="answer-p">
            <div class="wysiwyg-editor">
                <div v-html="sanitizedHtmlAnswer"></div>
            </div>
            </p>
        </div>
    </div>
</template>

<style scoped>
.card-divider {
    width: 100%;
    border-top: 1px solid rgb(242, 233, 245);
}

.wrapper-desktop {
    justify-content: center !important;
    min-height: 50vh !important;
}

.mobile-show-answer-button {
    font-size: 16px;
    font-weight: 700;
    line-height: 1.375;
    color: #7a219e;
}

.card-wrapper {
    background-color: white;
    padding: 32px 24px;
    text-align: center;
    border-radius: 8px;
    border: 1px solid #F1E8F4;
    min-height: 25vh;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

@media (max-width: 1200px) {
    .question-p {
        font-size: 24px;
    }
}

.question-p {
    color: #4a5361;
    font-size: 28px;
    overflow-wrap: break-word;
    text-align: center;
}

.answer-p {
    font-size: 20px;
    color: #4a5361;
    overflow-wrap: break-word;
}

p {
    margin: 0;
}

@media (max-width: 500px) {
    .question-p {
        font-size: 16px;
    }

    .answer-p {
        font-size: 16px;
    }
}
</style>