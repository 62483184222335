<script>
export default {
    name: 'CardsStudied',
    props: {
        cards: {
            type: Object,
            required: true
        }
    },
    data() {
        return {
            msg: 'message',
            today: 50,
            yesterday: 250,
        }
    },
    methods: {
        getPercentageFromNumericValues(today, yesterday) {
            if (today < yesterday) {
                let x = 100 - ((today / yesterday) * 100)
                return `-${Math.round(x)}%`
            } else if (today === yesterday) {
                return '0%'
            } else {
                let y = 100 - ((yesterday / today) * 100)
                return `+${Math.round(y)}%`
            }
        },
    }
}

</script>

<template>
    <div class="component-card border" id="cardsStudied">
        <p class="cards-title">{{ $t("message.dashboard.cardsStudiedToday") }}</p>
        <p class="stats-highlight">{{ cards.today }}</p>
        <p class="small-stats"><span :class="cards.today > cards.yesterday ? 'positive' : 'negative'">{{
            getPercentageFromNumericValues(cards.today, cards.yesterday) }}</span> <span class="yesterday">
                &nbsp;{{ $t('message.dashboard.yesterday') }}</span> <span class="yesterday-number">{{ cards.yesterday }} {{$t('message.dashboard.cards')}}</span></p>
        <div class="im" v-if="cards.today > cards.yesterday">
            <img src="../../assets/graph/graph_up.svg" alt="" height="50">
        </div>
        <div class="im" v-else>
            <img src="../../assets/graph/graph_down.svg" alt="" height="50">
        </div>
    </div>
</template>

<style scoped>
.yesterday {
    color: #9a9ea6;
    white-space: nowrap;
}

.small-stats {
    font-size: 14px;
    margin: 0;
    line-height: 20px;
    display: flex;
    align-items: center;
    margin: 0;
}

.cards-title {
    color: #4a5361;
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
    margin: 0;
}

.im {
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

.yesterday-number {
    background-color: #F1E8F4;
    color: #A264BB;
    border-radius: 8px;
    padding: 0.2rem 1rem;
    margin-left: 0.5rem;
    white-space: nowrap;
}

.positive {
    color: #44ca2e;

}

.negative {
    color: #EF585D;
}

.stats-highlight {
    font-size: 32px;
    color: #7a219e;
    font-weight: 700;
    padding: 0;
    margin: 0;
}

.component-card {
    min-width: 250px;
    display: flex;
    flex-direction: column;
    gap: 16px;
    background-color: #fefefe;
    padding: 24px;
    height: 100%;
    flex: 1;
}
</style>