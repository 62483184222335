import { supabase } from "../common/database";
import { toRaw } from 'vue'
import { captureException } from "@sentry/vue";


export const SessionService = {
    async startSession(sessionInfo) {
        sessionInfo.streak = await this.getCurrentStreak(sessionInfo.created_by)
        return await supabase.from('session').insert(sessionInfo).select()
    },
    async getUsersSessions(userId) {
        return await supabase.from('session').select('*').eq('created_by', userId).gt('created_at', new Date(new Date().setDate(new Date().getDate() - 10)).toISOString());
    },
    filterSessionsFromLastMondayToNextSunday(objects) {
        const today = new Date();
        let currentDay = today.getDay();
        currentDay = (currentDay + 6) % 7;

        const lastMonday = new Date(today);
        lastMonday.setDate(today.getDate() - currentDay);
        lastMonday.setHours(0, 0, 0, 0);

        today.setHours(23, 59, 59, 999)
        const filteredObjects = objects.filter(obj => {
            const currentDate = new Date(obj.created_at);
            currentDate.setHours(0, 0, 0, 0);
            return currentDate >= lastMonday && currentDate <= today;
        });

        return filteredObjects;
    },
    filterSessionsFromYesterday(objects) {
        const yesterday = new Date();
        yesterday.setDate(yesterday.getDate() - 1);
        yesterday.setHours(0, 0, 0, 0);

        const filteredObjects = objects.filter(obj => {
            const objectDate = new Date(obj.created_at);

            objectDate.setHours(0, 0, 0, 0);

            return objectDate.getTime() === yesterday.getTime();
        });

        return filteredObjects;
    },
    filterSessionsFromToday(objects) {
        const today = new Date();
        today.setHours(0, 0, 0, 0);

        const filteredObjects = objects.filter(obj => {
            const objectDate = new Date(obj.created_at);

            objectDate.setHours(0, 0, 0, 0);

            return objectDate.getTime() === today.getTime();
        });

        return filteredObjects;
    },
    initDaysArray(filteredSessions) {
        let arr = [
            { day: 'Mon', studied: false }, { day: 'Tue', studied: false },
            { day: 'Wed', studied: false }, { day: 'Thu', studied: false },
            { day: 'Fri', studied: false }, { day: 'Sat', studied: false },
            { day: 'Sun', studied: false }
        ]
        filteredSessions.forEach(element => {
            let day = new Date(element.created_at).getDay() - 1
            if (day === -1) day = 6
            arr[day].studied = true
        });
        return arr
    },
    getLastSessionOfDay(daysSessions) {
        const lastSession = daysSessions.reduce((prev, current) => {
            return new Date(current.created_at) > new Date(prev.created_at) ? current : prev;
        }, daysSessions[0]);
        return toRaw(lastSession)
    },
    sumSessionTimesAndCards(sessionArray) {
        let time = 0
        let cards = 0

        sessionArray.forEach(session => {
            cards += session.cards_learned
            time += new Date(session.last_card).getTime() - new Date(session.created_at).getTime()
        });

        return { time, cards }
    },
    async getCurrentStreak(userId) {
        const sessionFetchResponse = await this.getUsersSessions(userId)
        if (sessionFetchResponse.error) {
            console.error(sessionFetchResponse.error)
            captureException(sessionFetchResponse.error)
            return
        }
        const todaysSessions = this.filterSessionsFromToday(sessionFetchResponse.data)
        const yesterdaysSessions = this.filterSessionsFromYesterday(sessionFetchResponse.data)
        const latestSessionToday = this.getLastSessionOfDay(todaysSessions)
        const latestSessionYesterday = this.getLastSessionOfDay(yesterdaysSessions)

        if (latestSessionToday) return latestSessionToday.streak
        if (latestSessionYesterday) return latestSessionYesterday.streak + 1
        return 1
    },
    async getStatistics(userId) {
        const sessionFetchResponse = await this.getUsersSessions(userId)
        if (sessionFetchResponse.error) {
            console.error(sessionFetchResponse.error)
            captureException(sessionFetchResponse.error)
            return
        }
        let sessionsMondayToSunday = this.filterSessionsFromLastMondayToNextSunday(sessionFetchResponse.data)
        let daysArray = this.initDaysArray(sessionsMondayToSunday)

        let todaysSessions = this.filterSessionsFromToday(sessionFetchResponse.data)
        let yesterdaysSessions = this.filterSessionsFromYesterday(sessionFetchResponse.data)

        let result = this.sumSessionTimesAndCards(todaysSessions)
        let todayCards = result.cards
        let todayTime = result.time
        result = this.sumSessionTimesAndCards(yesterdaysSessions)
        let yesterdayCards = result.cards
        let yesterdayTime = result.time

        const streak = await this.getCurrentStreak(userId)

        let data = {
            streak: streak,
            days: daysArray,
            time: {
                yesterday: yesterdayTime,
                today: todayTime
            },
            cards: {
                yesterday: yesterdayCards,
                today: todayCards
            }
        }
        return data
    }
}