import { createRouter, createWebHistory } from 'vue-router'
import Study from './views/Study.vue'
import SignUp from './views/SignUp.vue'
import { supabase } from './common/database.js'
import Dashboard from './views/Dashboard.vue'
import { toast } from 'vue3-toastify'
import { decodeToken } from './common/util.js'
import { captureException, setUser as setSentryUser } from '@sentry/vue'
import MaintenanceComponent from './components/common/Maintenance.vue'

const routes = [
	{
		path: '/',
		redirect: '/dashboard',
	},
	{
		path: '/dashboard',
		name: 'dashboard',
		component: Dashboard,
		meta: { requiresAuth: true },
	},
	{
		path: '/decks',
		name: 'decks',
		component: () => import('./views/Decks.vue'),
		meta: { requiresAuth: true },
	},
	{
		path: '/signup',
		name: 'signup',
		component: SignUp,
		meta: { hideForAuth: true },
	},
	{
		path: '/signin',
		redirect: (to) => {
			return { path: '/signup', query: { mode: 'signin' } }
		},
		meta: { hideForAuth: true },
	},
	{
		path: '/onboarding',
		name: 'onboarding',
		component: () => import('./views/onboarding.vue'),
		meta: { requiresAuth: true },
	},

	{
		path: '/decks/:id',
		name: 'deck',
		component: () => import('./views/Deck.vue'),
		meta: { requiresAuth: true },
	},
	{
		path: '/create',
		name: 'create',
		component: () => import('./views/CreateDeck.vue'),
		meta: { requiresAuth: true },
	},
	{
		path: '/study/:id?',
		name: 'Study',
		component: Study,
		meta: { requiresAuth: true },
	},
	{
		path: '/opt-in/uuid/:uuid',
		name: 'opt-in',
		component: () => import('./views/newsLetterOptIn.vue'),
		meta: { requiresAuth: true },
	},
	{
		path: '/study-session/:id',
		name: 'study-session',
		component: () => import('./views/CustomStudySession.vue'),
		meta: { requiresAuth: true },
	},
	{
		path: '/deck-bin',
		name: 'deck-bin',
		component: () => import('./views/DeckBin.vue'),
		meta: { requiresAuth: true },
	},
	{
		path: '/shop',
		name: 'subscription-shop',
		component: () => import('./views/SubscriptionShop.vue'),
		meta: { requiresAuth: true },
	},
	{
		path: '/logout',
		name: 'logout',
		beforeEnter: (to, from, next) => {
			logOut()
			next('/signup')
		},
		meta: { requiresAuth: false },
	},
	{
		path: '/maintenance',
		name: 'Maintenance',
		component: MaintenanceComponent,
	},
	{
		path: '/:catchAll(.*)',
		redirect: '/dashboard',
	},
]

async function logOut() {
	const { error } = await supabase.auth.signOut()
	if (error) {
		console.error(error)
		captureException(error)

	} else {
		setSentryUser(null)
		router.push({ name: 'signup' })
	}
	localStorage.setItem('debug-logout', true)
}

export const router = createRouter({
	history: createWebHistory(),
	routes,
})

router.onError((err) => {
	console.error(err)
	captureException(err)
	toast('Routing error')
})

async function isLoggedIn() {
	const session = await supabase.auth.getSession()
	const user = session.data.session?.user
	
	if (user){
		setSentryUser({
			id: user?.id,
			email: user?.email
		})
	}

	return null != user
}

router.beforeEach((to, from, next) => {
	const maintenanceMode = import.meta.env.VITE_MAINTENANCE_MODE === 'true'
	if (maintenanceMode && to.name !== 'Maintenance') {
		next({ name: 'Maintenance' })
	} else if (!maintenanceMode && to.name === 'Maintenance') {
		next({ name: 'dashboard' })
	} else {
		next()
	}
})

router.beforeEach((to, from, next) => {
	isLoggedIn().then((isLoggedIn) => {
		if (to.matched.some((record) => record.meta.requiresAuth)) {
			if (!isLoggedIn) {
				next({ name: 'signup' })
			} else {
				fetchUserProfile().then((profile) => {
					if (profile.has_onboarded) {
						if (to.name === 'onboarding') {
							next({ name: 'dashboard' })
						}
						next()
					} else {
						if (to.name !== 'onboarding') {
							next({ name: 'onboarding' })
						} else {
							next()
						}
					}
				})
			}
		} else if (to.matched.some((record) => record.meta.hideForAuth)) {
			if (isLoggedIn) {
				next({ name: 'decks' })
			} else {
				next()
			}
		} else {
			next()
		}
	})
})

async function fetchUserProfile() {
	const session = await supabase.auth.getSession()
	const access_token = session.data.session.access_token
	const decoded = decodeToken(access_token)
	if (!decoded) return null
	let userId = decoded.profile_id

	const { data, error } = await supabase.from('profiles').select('*').eq('id', userId).single()

	if (error) {
		console.error('Error fetching profile:', error)
		captureException(error)
		return null
	}

	return data
}
